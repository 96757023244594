<div class="tool-container">
  <button
    id="funct-test-layer-legend-button"
    [class.uk-button-primary]="isLegendDisplayed"
    class="uk-icon-button uk-button-default"
    uk-icon="paint-bucket"
    (click)="toggleLegend()"
    matTooltip="Layer legend"
    matTooltipPosition="below"
  ></button>
  <button
    id="funct-test-layer-information-button"
    [class.uk-button-primary]="isInfoDisplayed"
    class="uk-icon-button uk-button-default"
    uk-icon="info"
    (click)="toggleInformation()"
    matTooltip="Information"
    matTooltipPosition="below"
  ></button>

  <!--
  Only for calculation module
  -->
  @if (layer.category == calculation_module_name) {
    <button
      class="uk-icon-button uk-button-default"
      (click)="export()"
      matTooltip="Download CM result"
      matTooltipPosition="below"
    >
      <img class="icon-download" src="/assets/icons/logo_download_all.svg" />
    </button>
    @if (
      isLoggedIn && layerType$() !== 'custom' && layerType$() !== '' && workspaceName$() !== ''
    ) {
      <button
        uk-icon="folder"
        class="uk-icon-button uk-button-default"
        (click)="saveInPersonalLayer()"
        matTooltip="Save as personal layer"
        matTooltipPosition="below"
      ></button>
    }
  }
  <!--  =========================  -->

  @if (layer.category != calculation_module_name) {
    <span>
      <button
        class="uk-icon-button uk-button-default"
        matTooltip="Download default dataset"
        id="funct-test-layer-download-default-dataset"
      >
        @if (layer.download_url) {
          <a href="{{ layer.download_url }}" target="_blank" download="{{ layer.download_url }}">
            <img class="icon-download" src="/assets/icons/logo_download_all.svg"
          /></a>
        }
      </button>
      <button
        id="funct-test-layer-download-selection"
        [hidden]="!allowDownload$()"
        class="uk-icon-button uk-button-default"
        (click)="export()"
        matTooltip="Download layer selection"
        matTooltipPosition="below"
      >
        <img class="icon-download" src="/assets/icons/logo_download_selection.svg" />
      </button>
      @if (isLoading$()) {
        <div uk-spinner></div>
      }
    </span>
  }
</div>

<!--
  Layer legend
-->
<div [hidden]="!isLegendDisplayed" class="uk-card uk-card-default uk-card-body">
  <h3 class="uk-card-title">Legend</h3>
  <div class="card-content">
    @if (!layer.custom_symbology) {
      <img
        [hidden]="!displayLegend"
        src="{{ imageUrl }}{{ layer.layer_type }}"
        (load)="endLoadLegend()"
      />
      @if (!displayLegend) {
        <div class="loader" uk-spinner></div>
      }
    } @else {
      <app-custom-symbology [symbology]="layer.custom_symbology"></app-custom-symbology>
    }
  </div>
</div>

@if (isLegendDisplayed) {
  <div class="uk-margin"></div>
}

<!--
  Layer information
-->
@if (isInfoDisplayed) {
  <div class="uk-card uk-card-default uk-card-body">
    <h3 class="uk-card-title">Information</h3>
    @if (layer.description) {
      <div class="card-content">
        <p>
          Information on the layer can be found here:
          <a href="{{ layer.description }}" target="_blank"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="20"
              height="20"
              viewBox="0,0,256,256"
            >
              <g
                fill="#1e87f0"
                fill-rule="nonzero"
                stroke="none"
                stroke-width="1"
                stroke-linecap="butt"
                stroke-linejoin="miter"
                stroke-miterlimit="10"
                stroke-dasharray=""
                stroke-dashoffset="0"
                font-family="none"
                font-weight="none"
                font-size="none"
                text-anchor="none"
                style="mix-blend-mode: normal"
              >
                <g transform="scale(5.12,5.12)">
                  <path
                    d="M46.79297,22.08984l-18.88281,-18.88281c-0.80078,-0.80469 -1.85547,-1.20703 -2.91016,-1.20703c-1.05469,0 -2.10937,0.40234 -2.91016,1.20703l-3.73437,3.73437l4.62109,4.62109c1.53516,-0.90234 3.53516,-0.70703 4.85156,0.60938c1.31641,1.31641 1.50781,3.31641 0.60547,4.84766l4.54297,4.54297c1.53516,-0.90234 3.53516,-0.70703 4.85156,0.60938c1.5625,1.5625 1.5625,4.09375 0,5.65625c-1.5625,1.5625 -4.09375,1.5625 -5.65625,0c-1.31641,-1.31641 -1.51172,-3.31641 -0.60937,-4.85156l-4.54297,-4.54297c-0.32422,0.19141 -0.66406,0.33203 -1.01953,0.42188v12.28516c1.72266,0.44531 3,1.99609 3,3.85938c0,2.21094 -1.78906,4 -4,4c-2.21094,0 -4,-1.78906 -4,-4c0,-1.86328 1.27734,-3.41406 3,-3.85937v-12.28516c-0.66797,-0.17187 -1.30469,-0.50391 -1.82812,-1.02734c-1.31641,-1.31641 -1.50781,-3.31641 -0.60547,-4.84766l-4.625,-4.625l-13.73437,13.73438c-1.60938,1.60547 -1.60938,4.21484 0,5.82031l18.88281,18.88281c0.80078,0.80469 1.85547,1.20703 2.91016,1.20703c1.05469,0 2.10938,-0.40234 2.91016,-1.20703l18.88281,-18.88281c1.60938,-1.60547 1.60938,-4.21484 0,-5.82031z"
                  ></path>
                </g>
              </g>
            </svg>
            Repository link</a
          >
        </p>
      </div>
    }
  </div>
}

@if (isInfoDisplayed) {
  <div class="uk-margin"></div>
}
