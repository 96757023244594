import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BaseService } from '@bases/base.service'
import { DEFAULT_DROP_DOWN_BUTTON } from '@components/_panel-right/results-tab/result-manager/result-manager.data'
import { CALCULATION_MODULE_CATEGORY } from '@core/constants/constant.data'
import { LAYERS_ARRAY } from '@core/constants/layers.data'
import { LayersService } from '@core/services/layers.service'
import { LoaderService } from '@core/services/loader.service'
import { ToasterService } from '@core/services/toaster.service'
import { isNullOrUndefinedString } from '@services/core.utilities'

@Injectable()
export class LayerInteractionService extends BaseService {
  // @ToDo: seems to be used as a signal
  public inputsCM: any[] = []

  constructor(
    http: HttpClient,
    loaderService: LoaderService,
    toasterService: ToasterService,
    private _layerService: LayersService,
  ) {
    super(http, loaderService, toasterService)
  }

  layerExists(layer) {
    const arr_size = LAYERS_ARRAY.filter((x) => x.id === layer.id).length
    return arr_size !== 0
  }

  removeLayer(id) {
    LAYERS_ARRAY.splice(
      LAYERS_ARRAY.findIndex((x) => x.id === id),
      1,
    )
  }

  removeAllUserLayers() {
    for (let i = LAYERS_ARRAY.length - 1; i >= 0; i--) {
      if (LAYERS_ARRAY[i].id !== 0) {
        LAYERS_ARRAY.splice(i, 1)
      }
    }
  }

  addNewLayer(name, workspaceName, id, layer_type) {
    const newLayerAdded = LAYERS_ARRAY.push(Object.assign({}, LAYERS_ARRAY[0]))

    LAYERS_ARRAY[newLayerAdded - 1] = {
      ...LAYERS_ARRAY[newLayerAdded - 1],
      ...{
        name: name,
        id: id,
        layer_type: layer_type,
        workspaceName: workspaceName,
        ref: [DEFAULT_DROP_DOWN_BUTTON],
        category: '',
        isSelected: false,
        download_url: '',
        description: '',
        projects: [], // define no project instead copy the first layer of the list
      },
    }
  }

  addNewCMLayer(name, workspaceName, id, symbology_layer_type, type_of_layer, symb?, layer_id = 0) {
    const newCMLayer = {
      id: layer_id, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
      name: name,
      category: CALCULATION_MODULE_CATEGORY,
      isSelected: true,
      workspaceName: workspaceName,
      zoomLevel: 0,
      ref: [DEFAULT_DROP_DOWN_BUTTON, CALCULATION_MODULE_CATEGORY],
      styleName: '',
      layer_type: symbology_layer_type,
      type_of_layer: type_of_layer,
      cm_id: id, // @ToDo: replace name
      isLoading: false,
      custom_symbology: symb,
    }

    this._layerService.layers$.update((layers: any) => {
      return [...layers, newCMLayer]
    })
  }

  getRefFromLayerName(name: string): any[] {
    const layer = LAYERS_ARRAY.filter((x) => x.workspaceName === name)[0]
    if (isNullOrUndefinedString(layer)) {
      return [DEFAULT_DROP_DOWN_BUTTON]
    }
    return layer.ref
  }
}
