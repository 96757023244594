<div class="file-form-div">
  @if (uploadedFiles.length == 0) {
    <span class="no-personal-layer-span">No personal layer</span>
  } @else {
    <div class="table-container">
      <!-- Material Table -->
      <table mat-table [dataSource]="uploadedFiles">
        <!-- Filename Column -->
        <ng-container matColumnDef="filename">
          <th mat-header-cell *matHeaderCellDef class="file-table-header">Filename</th>
          <td mat-cell *matCellDef="let upFile">{{ upFile.name }}</td>
        </ng-container>

        <!-- Layer Column -->
        <ng-container matColumnDef="layer">
          <th mat-header-cell *matHeaderCellDef class="file-table-header">Layer type</th>
          <td mat-cell *matCellDef="let upFile">{{ upFile.layerName }}</td>
        </ng-container>

        <!-- Size Column -->
        <ng-container matColumnDef="size">
          <th mat-header-cell *matHeaderCellDef class="file-table-header">Size</th>
          <td mat-cell *matCellDef="let upFile">
            <!--In this case we assume that upFile.size default format is in MB-->
            {{
              upFile.size * 1000 > 99999
                ? (upFile.size / 1000).toFixed(1) + ' GB'
                : upFile.size * 1000 > 99
                  ? upFile.size.toFixed(1) + ' MB'
                  : (upFile.size * 1000).toFixed(1) + ' KB'
            }}
          </td>
        </ng-container>

        <!-- Share Column -->
        <ng-container matColumnDef="share">
          <th mat-header-cell *matHeaderCellDef class="file-table-header">Share</th>
          <td mat-cell *matCellDef="let upFile">
            @if (upFile.shared_with.length > 0) {
              Shared
            } @else {
              Private
            }
          </td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="actions-header file-table-header">
            <button mat-icon-button (click)="getFiles()" matTooltip="Refresh">
              <mat-icon>refresh</mat-icon>
            </button>
          </th>

          <td mat-cell *matCellDef="let upFile" class="actions-cell">
            <button mat-icon-button (click)="selectFileToUpdate(upFile)" matTooltip="Edit">
              <mat-icon fontIcon="edit">settings</mat-icon>
            </button>

            <button mat-icon-button (click)="download(upFile)" matTooltip="Download">
              <mat-icon fontIcon="download"></mat-icon>
            </button>

            <button
              mat-icon-button
              (click)="openUploadDeletionConfirmationDialog(upFile.id, upFile)"
              matTooltip="Delete"
            >
              <mat-icon fontIcon="delete_forever"></mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Header and Row Definitions -->
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
          class="bold-header"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          [ngClass]="{ 'highlight-row': row.id === selectedFileToUpdate$()?.id }"
        ></tr>
      </table>
    </div>
  }

  @if (this.selectedFileToUpdate$()) {
    <div class="layer-upload">
      <mat-card-title class="personal-layer-title"
        >Update <b>{{ selectedFileToUpdate$().name }}</b>
      </mat-card-title>
    </div>
    <div class="layer-file-type-div">
      <mat-form-field class="file-input">
        <input
          matInput
          type="text"
          matTooltip="You can't change the uploaded file."
          matTooltipPosition="above"
          [placeholder]="selectedFileToUpdate$().name"
          disabled
        />
      </mat-form-field>
      <mat-form-field class="layer-type-input">
        <mat-label>
          [{{ selectedFileToUpdate$().layer_type }}] {{ selectedFileToUpdate$().layerName }}
        </mat-label>
        <mat-select
          matTooltip="You can't change the type of layer."
          matTooltipPosition="above"
          [disabled]="true"
          id="layer-type"
        >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="share-layer-div">
      <mat-form-field class="share-file-input">
        <input
          matInput
          [value]="parsedEmailList().join('; ')"
          [formControl]="editEmailFormControl"
          [errorStateMatcher]="matcher"
          [matTooltip]="emailListTooltipMessage()"
          matTooltipPosition="above"
          placeholder="jane.doe@example.com; john.doe@example.com"
          class="share-layer-input"
        />
        <mat-hint>Separate the email addresses with a semicolon <b>' ; '</b></mat-hint>
        <ng-container>
          @if (emailFormControl.hasError('email') && !emailFormControl.hasError('required')) {
            <mat-error>Please enter a valid email address</mat-error>
          }
        </ng-container>
      </mat-form-field>
      <button
        mat-flat-button
        color="warn"
        (click)="cancelLayerUpdate()"
        class="cancel-layer-button"
      >
        Cancel
      </button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="!hasEmailListChanged$()"
        (click)="openUpdateSharedConfirmationDialog()"
        class="upload-layer-button"
      >
        @if (isUploading) {
          <!--To-Do: Replace uk-spinner with mat-spinner-->
          <!-- <mat-spinner diameter="50" class="button-spinner"></mat-spinner> -->
          <div uk-spinner="ratio:1"></div>
        } @else {
          <span>Update layer</span>
        }
      </button>
    </div>
  } @else {
    <div>
      <div class="layer-upload">
        <mat-card-title class="personal-layer-title">Add a personal layer</mat-card-title>
      </div>
      <div class="layer-file-type-div">
        <mat-form-field class="file-input">
          <mat-label>Select your layer file</mat-label>
          <ngx-mat-file-input
            [formControl]="fileControl"
            [multiple]="multiple"
            accept=".tif,.csv,.geojson"
            id="layer-file"
            (change)="onFileChange($event)"
            matTooltip="Select your layer file (.tif, .csv or .geojson)"
            matTooltipPosition="above"
          >
          </ngx-mat-file-input>
        </mat-form-field>
        <mat-form-field class="layer-type-input">
          <mat-label for="layer-type">Select the layer type</mat-label>
          <mat-select
            [formControl]="layerTypeControl"
            matTooltip="Select type of the layer"
            matTooltipPosition="above"
            id="layer-type"
          >
            <mat-option value="Geojson">Geojson</mat-option>
            @for (group of groupedLayers$(); track group) {
              <mat-optgroup [label]="group.category" class="mat-optgroup-layer-group">
                <ng-container>
                  @for (layer of group.layers; track layer) {
                    <mat-option [value]="layer.name">
                      {{ layer.name }}
                    </mat-option>
                  }
                </ng-container>
              </mat-optgroup>
            }
          </mat-select>
        </mat-form-field>
      </div>

      <div class="share-layer-div">
        <mat-form-field class="share-file-input">
          <mat-label>Share</mat-label>

          <input
            matInput
            type="email"
            [formControl]="emailFormControl"
            [errorStateMatcher]="matcher"
            [matTooltip]="emailListTooltipMessage()"
            matTooltipPosition="above"
            placeholder="jane.doe@example.com; john.doe@example.com"
            class="share-layer-input"
          />
          <mat-hint>Separate the email addresses with a semicolon <b>' ; '</b></mat-hint>
          <ng-container>
            @if (emailFormControl.hasError('email') && !emailFormControl.hasError('required')) {
              <mat-error>Please enter a valid email address</mat-error>
            }
          </ng-container>
        </mat-form-field>
        <button
          mat-flat-button
          color="primary"
          [disabled]="!isFileOk || !layerTypeControl.value"
          (click)="openUploadSharedConfirmationDialog()"
          class="upload-layer-button"
        >
          @if (isUploading) {
            <!--To-Do: Replace uk-spinner with mat-spinner-->
            <!-- <mat-spinner diameter="50" class="button-spinner"></mat-spinner> -->
            <div uk-spinner="ratio:1"></div>
          } @else {
            <span>Upload layer</span>

            @if (isFileOk) {
              <span>
                {{
                  file2Up.size / 1000 > 99999
                    ? (file2Up.size / 1000000000).toFixed(1) + ' GB'
                    : file2Up.size / 1000 > 999
                      ? (file2Up.size / 1000000).toFixed(1) + ' MB'
                      : (file2Up.size / 1000).toFixed(1) + ' KB'
                }}
              </span>
            }
          }
        </button>
      </div>
    </div>
  }
</div>
