import {
  HiddenLayerClass,
  LayerClass,
} from '@components/_panel-left/layers-tab/layers-interaction/layers-interaction.class'

import { DEFAULT_DROP_DOWN_BUTTON } from '@components/_panel-right/results-tab/result-manager/result-manager.data'
import { CALCULATION_MODULE_CATEGORY, INDUSTRY_LAYER_ORDER } from './constant.data'
import { NUTS0, NUTS2, NUTS3, POPULATION } from './scale.data'

export const RASTER_TYPE = 'raster'
export const VECTOR_TYPE = 'vector'

const GFA_TYPE = 'gross_floor_area'
const CP_TYPE = 'construction_periods'
const POPULATION_TYPE = 'pop_tot_curr_density'
const SOLAR_POTENTIAL_FIELDS_TYPE = 'solar_potential_fields'
const BUILDING_VOLUMES_TYPE = 'building_volumes'

const GITLAB_URL = 'https://gitlab.com/hotmaps'
const ID_DEFAULT_LAYER = 0 //Do not change! In the backend, if id=0 this indicates that it is not a personal layer

const ZOOM_LEVEL_DETECT_CHANGE = 10

export const LAYERS_ARRAY: LayerClass[] = [
  // DUMMY LAYER, used to display the Layers tab and File manager in OM4A
  // It also prevents upload layer from being filtered by upload.service
  // {
  //   id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
  //   name: 'GeoJSON',
  //   category: 'GeoJSON',
  //   isSelected: false,
  //   workspaceName: 'Geojson',
  //   zoomLevel: 0,
  //   ref: [DEFAULT_DROP_DOWN_BUTTON, ''],
  //   layer_type: 'Geojson',
  //   download_url: '',
  //   description: '',
  //   isLoading: false,
  //   dataType: RASTER_TYPE, // @todo to add geojson and use const for csv
  //   schema: '',
  //   projects: ['citiwatts', 'CoolLIFE', 'SAPHEA', 'openMod4Africa'],
  // },

  // Buildings
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Heat density total',
    category: 'Buildings',
    isSelected: false,
    workspaceName: 'heat_tot_curr_density',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'buildings'],
    layer_type: 'heat', // unity MWh/ha
    download_url:
      GITLAB_URL +
      '/buildings/heat/heat_tot_curr_density/raw/master/data/heat_tot_curr_density.tif',
    description: GITLAB_URL + '/buildings/heat/heat_tot_curr_density/blob/master/README.md',
    isLoading: false,
    dataType: RASTER_TYPE,
    schema: 'geo',
    projects: ['citiwatts', 'CoolLIFE', 'SAPHEA'],
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Heat density residential sector',
    category: 'Buildings',
    isSelected: false,
    workspaceName: 'heat_res_curr_density',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'buildings'],
    layer_type: 'heat', // unity MWh/ha
    download_url:
      GITLAB_URL +
      '/buildings/heat/heat_res_curr_density/raw/master/data/heat_res_curr_density.tif',
    description: GITLAB_URL + '/buildings/heat/heat_res_curr_density/blob/master/README.md',
    isLoading: false,
    dataType: RASTER_TYPE,
    // schema: 'geo', ?
    projects: ['citiwatts', 'CoolLIFE', 'SAPHEA'],
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Heat density non-residential sector',
    category: 'Buildings',
    isSelected: false,
    workspaceName: 'heat_nonres_curr_density',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'buildings'],
    layer_type: 'heat', // unity MWh/ha
    download_url:
      GITLAB_URL +
      '/buildings/heat/heat_nonres_curr_density/raw/master/data/heat_nonres_curr_density.tif',
    description: GITLAB_URL + '/buildings/heat/heat_nonres_curr_density/blob/master/README.md',
    isLoading: false,
    dataType: RASTER_TYPE,
    // schema: 'geo', ?
    projects: ['citiwatts', 'CoolLIFE', 'SAPHEA'],
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Cooling density total',
    category: 'Buildings',
    isSelected: false,
    workspaceName: 'cool_tot_curr_density',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'buildings'],
    layer_type: 'cooling', // unity MWh/ha
    download_url:
      GITLAB_URL +
      '/buildings/heat/cool_tot_curr_density/raw/master/data/cool_tot_curr_density.tif',
    description: GITLAB_URL + '/buildings/heat/cool_tot_curr_density/blob/master/README.md',
    isLoading: false,
    dataType: RASTER_TYPE,
    schema: 'geo',
    projects: ['citiwatts', 'CoolLIFE', 'SAPHEA'],
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Gross floor area total',
    category: 'Buildings',
    isSelected: false,
    workspaceName: 'gfa_tot_curr_density',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'buildings'],
    layer_type: GFA_TYPE, // unity m2/ha //@ToDo: not correspond to geoserver style config, but has to be GFA_TYPE to appears in same category
    download_url:
      GITLAB_URL + '/buildings/gfa_tot_curr_density/raw/master/data/gfa_tot_curr_density.tif',
    description: GITLAB_URL + '/buildings/gfa_tot_curr_density/blob/master/README.md',
    isLoading: false,
    dataType: RASTER_TYPE,
    schema: 'geo',
    projects: ['citiwatts', 'CoolLIFE', 'SAPHEA'],
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Gross floor area residential',
    category: 'Buildings',
    isSelected: false,
    workspaceName: 'gfa_res_curr_density',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'buildings'],
    layer_type: GFA_TYPE, // unity m2/ha
    download_url:
      GITLAB_URL + '/buildings/gfa_res_curr_density/raw/master/data/gfa_res_curr_density.tif',
    description: GITLAB_URL + '/buildings/gfa_res_curr_density/blob/master/README.md',
    isLoading: false,
    dataType: RASTER_TYPE,
    schema: 'geo',
    projects: ['citiwatts', 'CoolLIFE', 'SAPHEA'],
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Gross floor area non-residential',
    category: 'Buildings',
    isSelected: false,
    workspaceName: 'gfa_nonres_curr_density',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'buildings'],
    layer_type: GFA_TYPE, // unity m2/ha
    download_url:
      GITLAB_URL + '/buildings/gfa_nonres_curr_density/raw/master/data/gfa_nonres_curr_density.tif',
    description: GITLAB_URL + '/buildings/gfa_nonres_curr_density/blob/master/README.md',
    isLoading: false,
    dataType: RASTER_TYPE,
    schema: 'geo',
    projects: ['citiwatts', 'CoolLIFE', 'SAPHEA'],
  },

  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Building volumes total',
    category: 'Buildings',
    isSelected: false,
    workspaceName: 'vol_tot_curr_density',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'buildings'],
    layer_type: BUILDING_VOLUMES_TYPE, // unity m3/ha
    download_url:
      GITLAB_URL + '/buildings/vol_tot_curr_density/raw/master/data/vol_tot_curr_density.tif',
    description: GITLAB_URL + '/buildings/vol_tot_curr_density/blob/master/README.md',
    isLoading: false,
    dataType: RASTER_TYPE,
    schema: 'geo',
    projects: ['citiwatts', 'CoolLIFE', 'SAPHEA'],
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Building volumes residential',
    category: 'Buildings',
    isSelected: false,
    workspaceName: 'vol_res_curr_density',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'buildings'],
    layer_type: BUILDING_VOLUMES_TYPE, // unity m3/ha
    download_url:
      GITLAB_URL + '/buildings/vol_res_curr_density/raw/master/data/vol_res_curr_density.tif',
    description: GITLAB_URL + '/buildings/vol_res_curr_density/blob/master/README.md',
    isLoading: false,
    dataType: RASTER_TYPE,
    schema: 'geo',
    projects: ['citiwatts', 'CoolLIFE', 'SAPHEA'],
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Building volumes non-residential',
    category: 'Buildings',
    isSelected: false,
    workspaceName: 'vol_nonres_curr_density',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'buildings'],
    layer_type: BUILDING_VOLUMES_TYPE, // unity m3/ha
    download_url:
      GITLAB_URL + '/buildings/vol_nonres_curr_density/raw/master/data/vol_nonres_curr_density.tif',
    description: GITLAB_URL + '/buildings/vol_nonres_curr_density/blob/master/README.md',
    isLoading: false,
    dataType: RASTER_TYPE,
    schema: 'geo',
    projects: ['citiwatts', 'CoolLIFE', 'SAPHEA'],
  },

  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Share of gross floor area - constructions before 1975',
    category: 'Buildings',
    isSelected: false,
    workspaceName: 'ghs_built_1975_100_share',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'buildings'],
    layer_type: CP_TYPE, // unity %
    download_url:
      GITLAB_URL +
      '/buildings/construction_periods/ghs_built_1975_100_share/raw/master/data/GHS_BUILT_1975_100_share.tif',
    description:
      GITLAB_URL + '/buildings/construction_periods/ghs_built_1975_100_share/blob/master/README.md',
    isLoading: false,
    dataType: RASTER_TYPE,
    schema: 'geo',
    projects: ['citiwatts', 'CoolLIFE', 'SAPHEA'],
  },

  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Share of gross floor area - constructions between 1975 and 1990',
    category: 'Buildings',
    isSelected: false,
    workspaceName: 'ghs_built_1990_100_share',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'buildings'],
    layer_type: CP_TYPE, // unity %
    download_url:
      GITLAB_URL +
      '/buildings/construction_periods/ghs_built_1990_100_share/raw/master/data/GHS_BUILT_1990_100_share.tif',
    description:
      GITLAB_URL + '/buildings/construction_periods/ghs_built_1990_100_share/blob/master/README.md',
    isLoading: false,
    dataType: RASTER_TYPE,
    schema: 'geo',
    projects: ['citiwatts', 'CoolLIFE', 'SAPHEA'],
  },

  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Share of gross floor area - constructions between 1990 and 2000',
    category: 'Buildings',
    isSelected: false,
    workspaceName: 'ghs_built_2000_100_share',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'buildings'],
    layer_type: CP_TYPE, // unity %
    download_url:
      GITLAB_URL +
      '/buildings/construction_periods/ghs_built_2000_100_share/raw/master/data/GHS_BUILT_2000_100_share.tif',
    description:
      GITLAB_URL + '/buildings/construction_periods/ghs_built_2000_100_share/blob/master/README.md',
    isLoading: false,
    dataType: RASTER_TYPE,
    schema: 'geo',
    projects: ['citiwatts', 'CoolLIFE', 'SAPHEA'],
  },

  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Share of gross floor area - constructions between 2000 and 2014',
    category: 'Buildings',
    isSelected: false,
    workspaceName: 'ghs_built_2014_100_share',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'buildings'],
    layer_type: CP_TYPE, // unity %
    download_url:
      GITLAB_URL +
      '/buildings/construction_periods/ghs_built_2014_100_share/raw/master/data/GHS_BUILT_2014_100_share.tif',
    description:
      GITLAB_URL + '/buildings/construction_periods/ghs_built_2014_100_share/blob/master/README.md',
    isLoading: false,
    dataType: RASTER_TYPE,
    schema: 'geo',
    projects: ['citiwatts', 'CoolLIFE', 'SAPHEA'],
  },

  // Industry
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Industrial Sites Emissions',
    category: 'Industry',
    isSelected: false,
    workspaceName: 'industrial_database_emissions',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'industry'],
    layer_type: 'industrial_database_emissions', // unity tons/year
    order: INDUSTRY_LAYER_ORDER,
    download_url:
      GITLAB_URL +
      '/industrial_sites/industrial_sites_Industrial_Database/raw/master/data/Industrial_Database.csv?ref_type=heads&inline=false',
    description:
      GITLAB_URL + '/industrial_sites/industrial_sites_Industrial_Database/blob/master/README.md',
    isLoading: false,
    year: 2014,
    dataType: 'csv',
    schema: 'public', // why ?
    projects: ['citiwatts', 'SAPHEA'],
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Industrial Sites Excess Heat',
    category: 'Industry',
    isSelected: false,
    workspaceName: 'industrial_database_excess_heat',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'industry'],
    layer_type: 'industrial_database_excess_heat', // no unity
    order: INDUSTRY_LAYER_ORDER,
    download_url:
      GITLAB_URL +
      '/industrial_sites/industrial_sites_Industrial_Database/raw/master/data/Industrial_Database.csv?ref_type=heads&inline=false',
    description:
      GITLAB_URL + '/industrial_sites/industrial_sites_Industrial_Database/blob/master/README.md',
    isLoading: false,
    year: 2014,
    dataType: 'csv',
    schema: 'public', // why ?
    projects: ['citiwatts', 'SAPHEA'],
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Industrial Sites Company Name',
    category: 'Industry',
    isSelected: false,
    workspaceName: 'industrial_database_compagnyname',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'industry'],
    layer_type: 'industrial_database_companyname', // no unity
    order: INDUSTRY_LAYER_ORDER,
    download_url:
      GITLAB_URL +
      '/industrial_sites/industrial_sites_Industrial_Database/raw/master/data/Industrial_Database.csv?ref_type=heads&inline=false',
    description:
      GITLAB_URL + '/industrial_sites/industrial_sites_Industrial_Database/blob/master/README.md',
    isLoading: false,
    year: 2014,
    dataType: 'csv',
    schema: 'public', // why ?
    projects: ['citiwatts'],
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Industrial Sites Subsector',
    category: 'Industry',
    isSelected: false,
    workspaceName: 'industrial_database_subsector',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'industry'],
    layer_type: 'industrial_database_subsector', // legend : cement, chemical industry,...
    order: INDUSTRY_LAYER_ORDER,
    download_url:
      GITLAB_URL +
      '/industrial_sites/industrial_sites_Industrial_Database/raw/master/data/Industrial_Database.csv?ref_type=heads&inline=false',
    description:
      GITLAB_URL + '/industrial_sites/industrial_sites_Industrial_Database/blob/master/README.md',
    isLoading: false,
    year: 2014,
    dataType: 'csv',
    schema: 'public', // why ?
    projects: ['citiwatts'],
  },

  // Population
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Population total',
    category: POPULATION,
    isSelected: false,
    workspaceName: 'pop_tot_curr_density',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, POPULATION],
    layer_type: POPULATION_TYPE, // unity: Person/ha
    download_url:
      GITLAB_URL + '/population/pop_tot_curr_density/raw/master/data/pop_tot_curr_density.tif',
    description: GITLAB_URL + '/population/pop_tot_curr_density/blob/master/README.md',
    isLoading: false,
    dataType: RASTER_TYPE,
    schema: 'geo',
    projects: ['citiwatts'],
  },

  // Mobility
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Motorization rate',
    category: 'Mobility',
    isSelected: false,
    workspaceName: 'motorization_rate_nuts2_view',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'mobility'],
    layer_type: 'motorization_rate_nuts2_view', // unity: car/1000 hab.
    download_url:
      GITLAB_URL +
      '/mobility/motorization_rate_nuts2/raw/master/data/motorization_rate_nuts2.csv?ref_type=heads&inline=false',
    description: GITLAB_URL + '/mobility/motorization_rate_nuts2/blob/master/README.md',
    isLoading: false,
    dataType: 'csv',
    schema: 'geo',
    scales: [NUTS2],
    projects: ['citiwatts'],
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Electric Vehicle density',
    category: 'Mobility',
    isSelected: false,
    workspaceName: 'default_ev_density',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'mobility'],
    layer_type: 'default_ev_density', // unity: EV/ha
    download_url:
      GITLAB_URL + '/mobility/default_ev_density/raw/master/data/default_ev_density.tif',
    description: GITLAB_URL + '/mobility/default_ev_density/blob/master/README.md',
    isLoading: false,
    dataType: RASTER_TYPE,
    //schema: 'geo', ?
    projects: ['citiwatts'],
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Charging stations density',
    category: 'Mobility',
    isSelected: false,
    workspaceName: 'cs_density',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'mobility'],
    layer_type: 'cs_density', // unity: CS/ha
    download_url: GITLAB_URL + '/mobility/cs_density/raw/master/data/cs_density.tif',
    description: GITLAB_URL + '/mobility/cs_density/blob/master/README.md',
    isLoading: false,
    dataType: RASTER_TYPE,
    //schema: 'geo', ?
    projects: ['citiwatts'],
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Eurostat Cities Isodistances',
    category: 'Mobility',
    isSelected: false,
    workspaceName: 'rasterized_iso_urban',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'mobility'],
    layer_type: 'rasterized_iso_urban', // unity: km
    download_url:
      GITLAB_URL + '/mobility/rasterized_iso_urban/raw/master/data/rasterized_iso_urban.tif',
    description: GITLAB_URL + '/mobility/rasterized_iso_urban/blob/master/README.md',
    isLoading: false,
    dataType: RASTER_TYPE,
    //schema: 'geo', ?
    projects: ['citiwatts'],
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Additional Cities Isodistances',
    category: 'Mobility',
    isSelected: false,
    workspaceName: 'rasterized_iso_rural',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'mobility'],
    layer_type: 'rasterized_iso_rural', // unity: km
    download_url:
      GITLAB_URL + '/mobility/rasterized_iso_rural/raw/master/data/rasterized_iso_rural.tif',
    description: GITLAB_URL + '/mobility/rasterized_iso_rural/blob/master/README.md',
    isLoading: false,
    dataType: RASTER_TYPE,
    //schema: 'geo', ?
    projects: ['citiwatts'],
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Transborder commuter ratio',
    category: 'Mobility',
    isSelected: false,
    workspaceName: 'rasterized_border',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'mobility'],
    layer_type: 'rasterized_border', // unity: %
    download_url: GITLAB_URL + '/mobility/rasterized_border/raw/master/data/rasterized_border.tif',
    description: GITLAB_URL + '/mobility/rasterized_border/blob/master/README.md',
    isLoading: false,
    dataType: RASTER_TYPE,
    //schema: 'geo', ?
    projects: ['citiwatts'],
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Eurostat Cities ID',
    category: 'Mobility',
    isSelected: false,
    workspaceName: 'rasterized_urau',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'mobility'],
    layer_type: 'rasterized_urau', // unity: -
    download_url: GITLAB_URL + '/mobility/rasterized_urau/raw/master/data/rasterized_urau.tif',
    description: GITLAB_URL + '/mobility/rasterized_urau/blob/master/README.md',
    isLoading: false,
    dataType: RASTER_TYPE,
    //schema: 'geo', ?
    projects: ['citiwatts'],
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Additional  Cities ID',
    category: 'Mobility',
    isSelected: false,
    workspaceName: 'rasterized_urau_rural',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'mobility'],
    layer_type: 'rasterized_urau_rural', // unity: -
    download_url:
      GITLAB_URL + '/mobility/rasterized_urau_rural/raw/master/data/rasterized_urau_rural.tif',
    description: GITLAB_URL + '/mobility/rasterized_urau_rural/blob/master/README.md',
    isLoading: false,
    dataType: RASTER_TYPE,
    //schema: 'geo', ?
    projects: ['citiwatts'],
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Workplaces from OpenStreetMap',
    category: 'Mobility',
    isSelected: false,
    workspaceName: 'work_osm',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'mobility'],
    layer_type: 'work_osm', // unity: workplace/ha
    download_url: GITLAB_URL + '/mobility/work_osm/raw/master/data/work_osm.tif',
    description: GITLAB_URL + '/mobility/work_osm/blob/master/README.md',
    isLoading: false,
    dataType: RASTER_TYPE,
    //schema: 'geo', ?
    projects: ['citiwatts'],
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Working areas from Corine Land Cover',
    category: 'Mobility',
    isSelected: false,
    workspaceName: 'filtered_corine_land_cover',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'mobility'],
    layer_type: 'filtered_corine_land_cover', // unity: -
    download_url:
      GITLAB_URL +
      '/mobility/filtered_corine_land_cover/raw/master/data/filtered_corine_land_cover.tif',
    description: GITLAB_URL + '/mobility/filtered_corine_land_cover/blob/master/README.md',
    isLoading: false,
    dataType: RASTER_TYPE,
    //schema: 'geo', ?
    projects: ['citiwatts'],
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Points Of Interest',
    category: 'Mobility',
    isSelected: false,
    workspaceName: 'poi_osm',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'mobility'],
    layer_type: 'poi_osm', // unity: POIs/ha
    download_url: GITLAB_URL + '/mobility/poi_osm/raw/master/data/poi_osm.tif',
    description: GITLAB_URL + '/mobility/poi_osm/blob/master/README.md',
    isLoading: false,
    dataType: RASTER_TYPE,
    //schema: 'geo', ?
    projects: ['citiwatts'],
  },

  // Potentials
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Waste Water Treatment Plants - Power',
    category: 'R.E.S. Potential',
    isSelected: false,
    workspaceName: 'wwtp_power',
    zoomLevel: ZOOM_LEVEL_DETECT_CHANGE,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'potential'],
    layer_type: 'wwtp_power', // Unity: kW
    download_url:
      GITLAB_URL + '/potential/WWTP/raw/master/data/2015/WWTP_2015.csv?ref_type=heads&inline=false',
    description: GITLAB_URL + '/potential/WWTP/blob/master/README.md',
    isLoading: false,
    dataType: 'csv',
    schema: 'public',
    projects: ['citiwatts'],
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Waste Water Treatment Plants - Capacity',
    category: 'R.E.S. Potential',
    isSelected: false,
    workspaceName: 'wwtp_capacity',
    zoomLevel: ZOOM_LEVEL_DETECT_CHANGE,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'potential'],
    layer_type: 'wwtp_capacity', // Unity: Person Equivalent
    download_url:
      GITLAB_URL + '/potential/WWTP/raw/master/data/2015/WWTP_2015.csv?ref_type=heads&inline=false',
    description: GITLAB_URL + '/potential/WWTP/blob/master/README.md',
    isLoading: false,
    dataType: 'csv',
    schema: 'public',
    projects: ['citiwatts'],
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Agricultural residues',
    category: 'R.E.S. Potential',
    isSelected: false,
    workspaceName: 'agricultural_residues_view',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'potential'],
    layer_type: 'agricultural_residues_view', // Unity: PJ
    download_url:
      GITLAB_URL +
      '/potential/potential_biomass/raw/master/data/agricultural_residues.csv?ref_type=heads&inline=false',
    description: GITLAB_URL + '/potential/potential_biomass/blob/master/README.md',
    isLoading: false,
    year: 1970,
    dataType: 'csv',
    schema: 'geo',
    scales: [NUTS3], // NUTS2 : result not available, LAU2 : No data in result
    projects: ['citiwatts'],
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Livestock effluents',
    category: 'R.E.S. Potential',
    isSelected: false,
    workspaceName: 'livestock_effluents_view',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'potential'],
    layer_type: 'livestock_effluents_view', // Unity: PJ
    download_url:
      GITLAB_URL +
      '/potential/potential_biomass/raw/master/data/livestock_effluents.csv?ref_type=heads&inline=false',
    description: GITLAB_URL + '/potential/potential_biomass/blob/master/README.md',
    isLoading: false,
    year: 1970,
    schema: 'geo',
    dataType: 'csv',
    scales: [NUTS3], // NUTS2 : result not available, LAU2 : No data in result
    projects: ['citiwatts'],
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Forest residues',
    category: 'R.E.S. Potential',
    isSelected: false,
    workspaceName: 'potential_forest',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'potential'],
    layer_type: 'potential_forest', // Unity: MWh/year
    download_url:
      GITLAB_URL +
      '/potential/potential_biomass/raw/master/data/forest_residues.csv?ref_type=heads&inline=false',
    description: GITLAB_URL + '/potential_biomass/blob/master/README.md',
    isLoading: false,
    // before: dataType: "csv",
    dataType: RASTER_TYPE,
    schema: 'geo',
    projects: ['citiwatts'],
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Municipal solid waste',
    category: 'R.E.S. Potential',
    isSelected: false,
    workspaceName: 'solid_waste_view',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'potential'],
    layer_type: 'solid_waste_view', // Unity: PJ
    download_url:
      GITLAB_URL +
      '/potential/potential_municipal_solid_waste/raw/master/data/solid_waste.csv?ref_type=heads&inline=false',
    description: GITLAB_URL + '/potential/potential_municipal_solid_waste/blob/master/README.md',
    isLoading: false,
    year: 2011,
    schema: 'geo',
    dataType: 'csv',
    scales: [NUTS3],
    projects: ['citiwatts'],
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Wind potential at 50 meters',
    category: 'R.E.S. Potential',
    isSelected: false,
    workspaceName: 'wind_50m',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'potential'],
    layer_type: 'wind_50m', // Unity: [m/s]
    download_url: GITLAB_URL + '/potential/potential_wind/raw/master/data/wind_50m.tif',
    description: GITLAB_URL + '/potential/potential_wind/blob/master/README.md',
    isLoading: false,
    dataType: RASTER_TYPE,
    schema: 'geo',
    projects: ['citiwatts'],
  },

  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Solar radiation on building footprint',
    category: 'R.E.S. Potential',
    isSelected: false,
    workspaceName: 'solar_optimal_total',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'potential'],
    layer_type: 'solar_optimal_total', // Unity: kWh/m2
    download_url: GITLAB_URL + '/potential/potential_solar/raw/master/data/solar_opt.tif',
    description: GITLAB_URL + '/potential/potential_solar/blob/master/README.md',
    isLoading: false,
    dataType: RASTER_TYPE,
    schema: 'geo',
    projects: ['citiwatts'],
  },

  // shallow geothermal vector layer
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Geothermal potential Heat Conductivity ',
    category: 'R.E.S. Potential',
    isSelected: false,
    workspaceName: 'shallow_geothermal_potential',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'potential'] /*['no_showed']*/,
    layer_type: 'shallow_geothermal_potential', // Unity: W/mK
    download_url:
      GITLAB_URL +
      '/potential/potential_shallowgeothermal/-/archive/master/potential_shallowgeothermal-master.zip?path=data',
    description: GITLAB_URL + '/potential/potential_shallowgeothermal/blob/master/README.md',
    isLoading: false,
    year: 1970,
    // before: dataType: "shp",
    dataType: 'csv',
    schema: 'geo',
    projects: ['citiwatts', 'CoolLIFE', 'SAPHEA'],
  },

  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Potential solarthermal collectors - rooftop',
    category: 'R.E.S. Potential',
    isSelected: false,
    workspaceName: 'potential_solarthermal_collectors_rooftop',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'potential'],
    layer_type: SOLAR_POTENTIAL_FIELDS_TYPE, // Unity: MWh/ha
    download_url:
      GITLAB_URL +
      '/potential/potential_solarthermal_collectors_rooftop/raw/master/data/potential_solarthermal_collectors_rooftop.tif',
    description:
      GITLAB_URL + '/potential/potential_solarthermal_collectors_rooftop/blob/master/README.md',
    isLoading: false,
    dataType: RASTER_TYPE,
    //schema: 'geo', ?
    projects: ['citiwatts'],
  },

  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Potential solarthermal collectors - open field',
    category: 'R.E.S. Potential',
    isSelected: false,
    workspaceName: 'potential_solarthermal_collectors_open_field',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'potential'],
    layer_type: SOLAR_POTENTIAL_FIELDS_TYPE, // Unity: MWh/ha
    download_url:
      GITLAB_URL +
      '/potential/potential_solarthermal_collectors_open_field/raw/master/data/potential_solarthermal_collectors_open_field.tif',
    description:
      GITLAB_URL + '/potential/potential_solarthermal_collectors_open_field/blob/master/README.md',
    isLoading: false,
    dataType: RASTER_TYPE,
    //schema: 'geo', ?
    projects: ['citiwatts'],
  },

  // I would suggest to only show on of the geothermal layers - in DB integrated as vector and raster - which one do we need for the indicator?
  /*  {id: idDefaultLayer, name: 'Geothermal Potential Hc Class ', category: 'R.E.S. Potential', isSelected: false,
    workspaceName: 'potential_shallowgeothermal_hc_class', zoomLevel: 0, ref: ['overall', 'potential'], styleName: 'potential_shallowgeothermal_hc_class', layer_type: defaultLayerType,
description:   gitlabUrl + '/potential/potential_shallowgeothermal/blob/master/README.md'}, isLoading:false},*/

  // Climate

  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Average temperature',
    category: 'Climate',
    isSelected: false,
    workspaceName: 'land_surface_temperature',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'climate'],
    layer_type: 'land_surface_temperature', // Unity: °C
    download_url:
      GITLAB_URL + '/climate/land_surface_temperature/raw/master/data/land_surface_temperature.tif',
    description: GITLAB_URL + '/climate/land_surface_temperature/blob/master/README.md',
    isLoading: false,
    dataType: RASTER_TYPE,
    schema: 'geo',
    projects: ['citiwatts', 'CoolLIFE', 'SAPHEA'],
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Cooling degree days',
    category: 'Climate',
    isSelected: false,
    workspaceName: 'cdd_curr',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'climate'],
    layer_type: 'cdd_curr', // Unity: Degree days
    download_url: GITLAB_URL + '/climate/CDD_ha_curr/raw/master/data/CDD_curr.tif',
    description: GITLAB_URL + '/climate/CDD_ha_curr/blob/master/README.md',
    isLoading: false,
    dataType: RASTER_TYPE,
    schema: 'geo',
    projects: ['citiwatts', 'CoolLIFE', 'SAPHEA'],
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Heating degree days',
    category: 'Climate',
    isSelected: false,
    workspaceName: 'hdd_curr',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'climate'],
    layer_type: 'hdd_curr', // Unity: Degree days
    download_url: GITLAB_URL + '/climate/HDD_ha_curr/raw/master/data/HDD_curr.tif',
    description: GITLAB_URL + '/climate/HDD_ha_curr/blob/master/README.md',
    isLoading: false,
    dataType: RASTER_TYPE,
    schema: 'geo',
    projects: ['citiwatts', 'CoolLIFE', 'SAPHEA'],
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Solar radiation',
    category: 'Climate',
    isSelected: false,
    workspaceName: 'solar_radiation',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'climate'],
    layer_type: 'climate_solar_radiation', // Unity: kWh/m2
    download_url:
      GITLAB_URL + '/climate/climate_solar_radiation/raw/master/data/output_solar_radiation.tif',
    description: GITLAB_URL + '/climate/climate_solar_radiation/blob/master/README.md',
    isLoading: false,
    dataType: RASTER_TYPE,
    schema: 'geo',
    projects: ['citiwatts', 'CoolLIFE', 'SAPHEA'],
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Wind speed',
    category: 'Climate',
    isSelected: false,
    workspaceName: 'output_wind_speed',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'climate'],
    layer_type: 'output_wind_speed', // Unity: [m/s]
    download_url:
      GITLAB_URL + '/climate/climate_wind_speed/raw/master/data/EU_wind_speed_average.tif',
    description: GITLAB_URL + '/climate/climate_wind_speed/blob/master/README.md',
    isLoading: false,
    dataType: RASTER_TYPE,
    schema: 'geo',
    projects: ['citiwatts', 'CoolLIFE', 'SAPHEA'],
  },

  // Electricity
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Electricity CO2 emissions - country average',
    category: 'Electricity',
    isSelected: false,
    workspaceName: 'yearly_co2_emission',
    zoomLevel: 0,
    ref: [DEFAULT_DROP_DOWN_BUTTON, 'electricity'],
    layer_type: 'yearly_co2_emission', // Unity: kg/MWh
    download_url:
      GITLAB_URL +
      '/electricity/load_electricity/electricity_emissions_hourly/raw/master/data/Emissions_hourly.csv?ref_type=heads&inline=false',
    description:
      GITLAB_URL +
      '/electricity/load_electricity/electricity_emissions_hourly/blob/master/README.md',
    isLoading: false,
    layerName: 'yearly_co2_emission_factors_view',
    year: 2015,
    dataType: 'csv',
    // before: schema: "geo",
    schema: 'public',
    scales: [NUTS0],
    projects: ['citiwatts'],
  },
]

export const HIDDEN_LAYERS_ARRAY: HiddenLayerClass[] = [
  // For second input in CM - District Heating Potential : Economic Assessment
  // {
  //   id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
  //   name: "Heat density total 2", // Add for CM district heating potential economic assessment (need twice this layers)
  //   workspaceName: "heat_tot_curr_density",
  //   layer_type: "heat2",
  //   download_url:
  //     gitlabUrl +
  //     "/heat/heat_tot_curr_density/raw/master/data/heat_tot_curr_density.tif",
  // },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    workspaceName: 'nuts_id_number',
    name: 'nuts_id_number',
    layer_type: 'nuts_id_number',
    download_url: GITLAB_URL + '/nuts_id_number/raw/master/data/nuts_id_number.tif',
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    workspaceName: 'lau2_id_number',
    name: 'lau2_id_number',
    layer_type: 'lau2_id_number',
    download_url: GITLAB_URL + '/lau2_id_number/raw/master/data/lau2_id_number.tif',
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    workspaceName: 'gfa_res_curr_density',
    name: 'gfa_res_curr_density',
    layer_type: 'gfa_res_curr_density',
    download_url: GITLAB_URL + '/gfa_res_curr_density/raw/master/data/gfa_res_curr_density.tif',
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    workspaceName: 'gfa_nonres_curr_density',
    name: 'gfa_nonres_curr_density',
    layer_type: 'gfa_nonres_curr_density',
    download_url:
      GITLAB_URL + '/gfa_nonres_curr_density/raw/master/data/gfa_nonres_curr_density.tif',
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    workspaceName: 'heat_res_curr_density',
    name: 'heat_res_curr_density',
    layer_type: 'heat_res_curr_density',
    download_url:
      GITLAB_URL + '/heat/heat_res_curr_density/raw/master/data/heat_res_curr_density.tif',
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    workspaceName: 'heat_nonres_curr_density',
    name: 'heat_nonres_curr_density',
    layer_type: 'heat_nonres_curr_density',
    download_url:
      GITLAB_URL + '/heat/heat_nonres_curr_density/raw/master/data/heat_nonres_curr_density.tif',
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    workspaceName: 'ghs_built_1975_100_share',
    name: 'ghs_built_1975_100_share',
    layer_type: 'ghs_built_1975_100_share',
    download_url:
      GITLAB_URL +
      '/construction_periods/ghs_built_1975_100_share/raw/master/data/GHS_BUILT_1975_100_share.tif',
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    workspaceName: 'ghs_built_1990_100_share',
    name: 'ghs_built_1990_100_share',
    layer_type: 'ghs_built_1990_100_share',
    download_url:
      GITLAB_URL +
      '/construction_periods/ghs_built_1990_100_share/raw/master/data/GHS_BUILT_1990_100_share.tif',
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    workspaceName: 'ghs_built_2000_100_share',
    name: 'ghs_built_2000_100_share',
    layer_type: 'ghs_built_2000_100_share',
    download_url:
      GITLAB_URL +
      '/construction_periods/ghs_built_2000_100_share/raw/master/data/GHS_BUILT_2000_100_share.tif',
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    workspaceName: 'ghs_built_2014_100_share',
    name: 'ghs_built_2014_100_share',
    layer_type: 'ghs_built_2014_100_share',
    download_url:
      GITLAB_URL +
      '/construction_periods/ghs_built_2014_100_share/raw/master/data/GHS_BUILT_2014_100_share.tif',
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    workspaceName: 'building_footprint_tot_curr',
    name: 'building_footprint_tot_curr',
    layer_type: 'building_footprint_tot_curr',
    download_url:
      GITLAB_URL + '/building_footprint_tot_curr/raw/master/data/building_footprint_tot_curr.tif',
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    workspaceName: 'ground_conductivity',
    name: 'ground_conductivity',
    layer_type: 'ground_conductivity',
    download_url:
      GITLAB_URL + '/potential/ground_conductivity/raw/master/data/ground_conductivity.tif',
  },
  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    workspaceName: 'population_density',
    name: 'population_density',
    layer_type: POPULATION,
    download_url: GITLAB_URL + '/population_density/raw/main/data/raster_for_test.tif',
  },

  {
    id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Heat density total (future)',
    workspaceName: 'heat_tot_curr_density',
    layer_type: 'heat2', // unity MWh/ha
    download_url:
      GITLAB_URL + '/heat/heat_tot_curr_density/raw/master/data/heat_tot_curr_density.tif',
  },
]

export const CM_DEFAULT_LAYER = {
  id: ID_DEFAULT_LAYER, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
  name: '',
  category: CALCULATION_MODULE_CATEGORY,
  isSelected: true,
  workspaceName: '',
  zoomLevel: 0,
  ref: [DEFAULT_DROP_DOWN_BUTTON, CALCULATION_MODULE_CATEGORY],
  styleName: '',
  layer_type: CALCULATION_MODULE_CATEGORY,
  cm_id: '',
  isLoading: false,
}
