import { Component, Input } from '@angular/core'
import { LayerInteractionService } from '@components/_panel-left/layers-tab/layers-interaction/layers-interaction.service'
import { BusinessNameService } from '@core/services/business.service'
import { UploadService } from '@core/services/upload.service'
import { isNullOrUndefinedString } from '@services/core.utilities'
import { ExportDataService } from './export-data.service'

@Component({
  standalone: true,
  selector: 'app-export-data',
  templateUrl: './export-data.component.html',
  styleUrls: ['./export-data.component.css'],
  providers: [ExportDataService],
  imports: [],
})
export class ExportDataComponent {
  @Input() graphics
  @Input() indicators
  @Input() indicatorState
  @Input() graphState
  @Input() tabSelected
  @Input() refSelected

  constructor(
    private _exportDataService: ExportDataService,
    private _layerInteractionService: LayerInteractionService,
    private _uploadService: UploadService,
    private _businessNameService: BusinessNameService,
  ) {}

  exportIndicators() {
    const arrayTmp = this._summaryResultToCSV(
      this.indicators,
      this._layerInteractionService.inputsCM,
    )
    // get name of CM session to append to file name of exported CSV
    let cmSessionName

    try {
      cmSessionName = this.indicators.cmResult.layers[0].name
      cmSessionName = /.*(?=\s-\sCM\s-\s.*$)/g.exec(cmSessionName)
    } catch (e) {
      cmSessionName = undefined
    }

    this._exportData(arrayTmp, cmSessionName)
  }

  exportExtraOutputFilesCM() {
    let cmSessionName = this.indicators.cmResult.layers[0].name
    cmSessionName = /.*(?=\s-\sCM\s-\s.*$)/g.exec(cmSessionName)

    // export zip
    if (this.indicators.cmResult?.extra_zip_files !== undefined) {
      this.indicators.cmResult.extra_zip_files.forEach((extraZipFile) => {
        let fileId = extraZipFile.path.split('/').pop()
        this._uploadService.exportCMresultFile(fileId, 'zip', extraZipFile.name).then((data) => {
          const a = document.createElement('a')
          a.href = data.url
          a.download = cmSessionName + '_' + extraZipFile.name + '.zip'
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          URL.revokeObjectURL(data.url)
        })
      })
    }

    // export csv
    if (this.indicators.cmResult?.csv_files !== undefined) {
      this.indicators.cmResult.csv_files.forEach((csv_file) => {
        let fileId = csv_file.path.split('/').pop()
        this._uploadService.exportCMresultFile(fileId, 'csv', csv_file.name).then((data) => {
          const a = document.createElement('a')
          a.href = data.url
          a.download = cmSessionName + '_' + csv_file.name
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          URL.revokeObjectURL(data.url)
        })
      })
    }
  }

  exportGraphic() {
    const arrayTmp = this._chartsToCSV(this.graphics)
    this._exportData(arrayTmp)
  }

  hasIndicatorsDataToExport() {
    return (
      this.indicators.cmResult !== null ||
      this.indicators.personalLayerResult !== null ||
      this.indicators.summaryResult?.layers !== null
    )
  }

  hasExtraFilesToExport() {
    return (
      this.indicators.cmResult &&
      (this.indicators.cmResult.extra_zip_files?.length > 0 ||
        this.indicators.cmResult.csv_files?.length > 0)
    )
  }

  private _exportData(result, cmSessionName = undefined) {
    this._exportDataService.exportData(result, this.tabSelected, cmSessionName, this.refSelected)
  }

  private _summaryResultToCSV(result, inputsCM = null): any {
    let array = []
    const header = {
      '1': 'indicator',
      '2': 'value',
      '3': 'unit',
    }

    for (const res of Object.keys(result)) {
      // if no personal layer nor layers selected
      if (isNullOrUndefinedString(result[res]) || isNullOrUndefinedString(result[res].layers)) {
        continue
      }

      for (const entry of result[res].layers) {
        let displayLayerName = this._businessNameService.getReadableLayerName(entry.name)
        array.push({ indicator: displayLayerName }) // name of layer, file of personal layer or session of CM

        // Add input of CM

        if (entry.category.includes('Calculation module')) {
          for (const inputCM of Object.keys(inputsCM)) {
            // The condition under will be useful when we will fix the  loss of CM result when changing tab
            if (inputsCM[inputCM].cm_prefix + ' - ' + inputsCM[inputCM].cm_name == entry.name) {
              array.push({ '1': 'INPUT' })
              array.push(header)
              // Loop for input CM
              for (const valueInput of inputsCM[inputCM].inputs) {
                array.push({
                  indicator: valueInput.input_name,
                  value: valueInput.input_value,
                  unit: valueInput.input_unit,
                })
              }

              // All layer selected
              if (!isNullOrUndefinedString(inputsCM[inputCM].layersInputs)) {
                for (const layerInput of inputsCM[inputCM].layersInputs) {
                  if (isNullOrUndefinedString(layerInput)) {
                    continue
                  }
                  var correspondingDescription = inputsCM[inputCM].layersInputsDescription.filter(
                    (d) => d.type == layerInput.layer_type,
                  )
                  array.push({
                    indicator: correspondingDescription[0].description,
                    value: layerInput.name,
                  })
                }
              }

              array.push({ '1': 'OUTPUT' })
            }
          }
        }
        array.push(header)

        // Loop for layer, personal layer and CM result
        for (const entry_in_entry of entry.values) {
          let displayName = this._businessNameService.getReadableIndicatorsName(entry_in_entry.name)
          array.push({
            indicator: displayName,
            value: entry_in_entry.value,
            unit: entry_in_entry.unit,
          })
        }
        array.push('') // Put space between Layers indicator and CMs indicator
      }
    }

    return array
  }

  private _chartsToCSV(graphs) {
    var arrayTmp = []

    const header = {
      '1': 'serie',
      '2': 'label',
      '3': 'value',
    }
    arrayTmp.push(header)
    graphs.map((graph) => {
      arrayTmp.push({ name: graph.name })
      graph.data.map((data) => {
        data.data.map((d, currIndex) => {
          arrayTmp.push({
            serie: data.label,
            label: graph.labels[currIndex],
            value: d,
          })
        })
      })
    })
    return arrayTmp
  }
}
