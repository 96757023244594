@if (this.fileToDelete.shared_with.length > 0) {
  <h1 mat-dialog-title>Remove shared personal layer</h1>
} @else {
  <h1 mat-dialog-title>Remove personal layer</h1>
}
<div mat-dialog-content>
  <p>
    Are you sure you want to delete the personal layer <b>{{ this.fileToDelete.name }}</b> ?
  </p>

  @if (this.fileToDelete.shared_with.length == 1) {
    <p>This layer will no longer be accessible to the user it was shared with.</p>
  } @else if (this.fileToDelete.shared_with.length > 0) {
    <p>
      This layer will no longer be accessible to the users ({{
        this.fileToDelete.shared_with.length
      }}) it was shared with.
    </p>
  }
</div>

<div mat-dialog-actions align="end">
  <button mat-button (click)="close()">Cancel</button>
  <button mat-button (click)="save()" class="uk-text-danger">DELETE</button>
</div>
