<h2 mat-dialog-title class="dialog-title maintenance-warning">Maintenance notice</h2>
<button class="uk-modal-close-default" type="button" uk-close (click)="closeOnce()"></button>

<div mat-dialog-content>
  <p>
    The {{ appName }} toolbox will be subject to maintenance between <label class="maintenance-warning">{{ maintenanceStart }}</label> and <label class="maintenance-warning">{{ maintenanceEnd }}</label>. During this period of time time, you may experience temporary interruptions, including brief service outages or bugs and inconsistencies. 
  </p>
  <p>Thank you for your understanding, the {{ appName }} team.</p>
</div>

<div mat-dialog-actions align="end">
  <button mat-stroked-button color="basic" (click)="closeOnce()">
    Show again next time
  </button>

  <button mat-flat-button color="success" (click)="closeDefinitively()">
    Okay, don't tell me again
  </button>
</div>