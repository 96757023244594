/* ******************************************************************
 * * Copyright         : 2024 HES-SO Valais-Wallis - Institute of Informatics - EASILab
 * * Description       :
 * * Revision History  :
 * * Date           Author                              Comments
 * * ---------------------------------------------------------------------------
 * * 20.12.2017     Dany Djedovic                       Creation
 * *
 ******************************************************************/
import { animate, state, style, transition, trigger } from '@angular/animations'
import { DecimalPipe } from '@angular/common'
import { Component, effect, OnInit, signal } from '@angular/core'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MAX_SURFACE_VALUE_CM } from '@core/constants/constant.data'
import { HECTARE } from '@core/constants/scale.data'
import { SelectionScaleService } from '@core/services/selection-scale.service'
import { MapService } from '@pages/map/services/map.service'
import { NumberFormatPipe } from '@pipes/number-format.pipe'
import { isNullOrUndefinedString } from '@services/core.utilities'
import { SELECTION_TOOLS_BUTTONS } from './selection-button.data'
import { SelectionToolService } from './service/selection-tool.service'

@Component({
  standalone: true,
  selector: 'app-selection-tool',
  templateUrl: './selection-tool.component.html',
  styleUrls: ['./selection-tool.component.css'],
  animations: [
    trigger('containerTrigger', [
      transition(':enter', [style({ opacity: 0 }), animate(250, style({ opacity: 1 }))]),
      transition(':leave', [animate(250, style({ opacity: 0 }))]),
    ]),
    trigger('elementsTrigger', [
      state('in', style({ opacity: '1' })),
      transition('void => *', [style({ opacity: '0' }), animate('100ms 200ms')]),
      transition('* => void', [animate('50ms', style({ opacity: '0' }))]),
    ]),
  ],
  imports: [
    // Pipes
    NumberFormatPipe,
    DecimalPipe,

    // Material
    MatTooltipModule,
  ],
})
export class SelectionToolComponent implements OnInit {
  clearButtonText$ = signal<string>('')
  isClearBtnDisabled$ = signal<boolean>(true)

  isHectareSelected = false
  isLoadBtnDisabled = true
  stButtons = SELECTION_TOOLS_BUTTONS
  maxSurfaceValueCM = MAX_SURFACE_VALUE_CM

  private _defaultTextAreaSelected = 'Selected area'
  elementSelected = this._defaultTextAreaSelected

  private _fileToUpload: File = null

  constructor(
    private _mapService: MapService,
    public selectionScaleService: SelectionScaleService,
    public selectionToolService: SelectionToolService,
  ) {
    effect(() => {
      this._updateHectareState()
    })
  }

  ngOnInit() {
    this.subscribeMapService()
    this._updateHectareState()
  }

  subscribeMapService() {
    // subscribing to click event subject of MapService
    if (!isNullOrUndefinedString(this._mapService.clickEventSubjectObs)) {
      this._mapService.clickEventSubjectObs.subscribe(() => {
        this.cursorClick() // call cursor click method when we click anywhere in the map
      })
    }

    if (!isNullOrUndefinedString(this._mapService.drawCreatedSubjectObs)) {
      this._mapService.drawCreatedSubjectObs.subscribe(() => {
        this.cursorClick()
      })
    }

    if (!isNullOrUndefinedString(this.selectionToolService.buttonClearAll)) {
      this.selectionToolService.buttonClearAll.subscribe((value) => {
        this.isClearBtnDisabled$.set(!value)
      })
    }

    if (!isNullOrUndefinedString(this.selectionToolService.buttonLoadResultStatus)) {
      this.selectionToolService.buttonLoadResultStatus.subscribe((value) => {
        this.isLoadBtnDisabled = !value
      })
    }
  }

  cursorClick() {
    const activeDrawTool = this.getActiveDrawTool()
    const map = this._mapService.getMap()
    this.selectionToolService.activateClickSelectionTool()

    if (activeDrawTool.type !== 'click') {
      this.selectionToolService.activateDrawTool(map, activeDrawTool.type)
    }
  }

  /**
   * Draw method of the activated selection tool
   */
  drawTool(button: any) {
    this.deactivateAllDrawTools()
    button.isChecked = true

    if (button.type === 'click') {
      this.selectionToolService.activateClickSelectionTool()
    } else {
      const map = this._mapService.getMap()
      this.selectionToolService.activateDrawTool(map, button.type)
      this.stButtons[0].isChecked = false
    }

    if (this.selectionToolService.selectedSurface$() <= 0) {
      this.isClearBtnDisabled$.set(true)
    }
  }

  getActiveDrawTool() {
    for (const stButton of SELECTION_TOOLS_BUTTONS) {
      if (stButton.isChecked) return stButton
    }
    return undefined
  }

  deactivateAllDrawTools() {
    for (const stButton of SELECTION_TOOLS_BUTTONS) {
      stButton.isChecked = false
    }
  }

  uploadShapes(event: any) {
    const files = event.target.files
    this._fileToUpload = files.item(0)

    // Read file and insert data in geoJson variable.
    const fileReader = new FileReader()
    let geoJson: any

    fileReader.readAsText(this._fileToUpload, 'UTF-8')
    fileReader.onload = () => {
      geoJson = JSON.parse(<string>fileReader.result)
      this.selectionToolService.drawSelectionShapeFromFile(geoJson)
    }
    fileReader.onerror = (error) => {
      console.warn('uploadShapes', error)
    }
  }

  loadResultsButton() {
    this.selectionToolService.loadResultNuts()
  }

  /**
   * Clear all information in the info box
   */
  clearAllButton() {
    this.selectionToolService.clearAll()
    this.cursorClick()
  }

  clearLayers() {
    if (this.selectionToolService.nbOfDrawnElementSelected$() >= 1) {
      this.selectionToolService.deleteSelectedAreas()
    } else {
      this.selectionToolService.clearAll()
      const fileInput = document.getElementById('fileInput') as HTMLInputElement
      if (fileInput) {
        fileInput.value = ''
      }
      this.cursorClick()
    }

    if (
      this.selectionToolService.nbOfElementsSelected$() == 0 &&
      this.selectionToolService.nbOfDrawnElementSelected$() == 0
    ) {
      this.isClearBtnDisabled$.set(true)
      this.isLoadBtnDisabled = true
    }
  }

  private _updateHectareState() {
    if (this.selectionScaleService.currentScaleLevel$()) {
      if (this.selectionScaleService.currentScaleLevel$().displayName === HECTARE) {
        this.isHectareSelected = true
        this.elementSelected = 'Drawn areas'
      } else {
        this.isHectareSelected = false
        this.elementSelected = this._defaultTextAreaSelected
      }
    }
  }
}
