import { Component } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import {
  MatDialogContent,
  MatDialogModule,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog'
import { environment } from 'environments/environment'

@Component({
  selector: 'app-maintenance-popup',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, MatDialogTitle, MatDialogContent],
  templateUrl: './maintenance-popup.component.html',
  styleUrl: './maintenance-popup.component.scss',
})
export class MaintenancePopupComponent {
  appName = environment.appName
  formatter = new Intl.DateTimeFormat('en-CH', {
    dateStyle: 'full',
    timeStyle: 'short',
  })

  maintenanceStart = this.convertUTCDateToLocalTime(environment.maintenanceNotice.split(' - ')[0])
  maintenanceEnd = this.convertUTCDateToLocalTime(environment.maintenanceNotice.split(' - ')[1])

  constructor(private _dialogRef: MatDialogRef<MaintenancePopupComponent>) {}

  convertUTCDateToLocalTime(dateString) {
    return this.formatter.format(new Date(dateString + 'Z'))
  }

  closeOnce() {
    this._dialogRef.close(false)
  }

  closeDefinitively() {
    this._dialogRef.close(false)
    localStorage.setItem('maintenanceNoticeRead', JSON.stringify(environment.maintenanceNotice))
  }
}
