@if (isLoggedIn) {
  <div class="dialog-container">
    <div class="dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
      <h2 mat-dialog-title class="dialog-title">Welcome, {{ firstname }}</h2>

      <button
        mat-dialog-close
        mat-icon-button
        aria-label="Close dialog"
        class="dialog-close-button"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <mat-dialog-content class="dialog-content">
      <mat-grid-list cols="2" rowHeight="fit">
        <mat-card class="disk-space-card">
          <mat-card-header class="file-card-header">
            <mat-card-title
              >User Disk Space : {{ ((diskSpaceUsed / diskSpaceMax) * 100).toFixed(2) }} %
              used</mat-card-title
            >
          </mat-card-header>

          <mat-card-content>
            <app-chart
              type="bar"
              [labels]="diskSpaceLabels"
              [options]="diskSpaceOptions"
              [datasets]="diskSpaceDataset"
              [isLoading]="false"
              class="disk-space-chart"
            >
            </app-chart>
          </mat-card-content>
        </mat-card>

        <mat-card class="upload-card">
          <mat-card-content class="upload-file-card-content">
            <app-upload></app-upload>

            @if (localhost) {
              <p class="local-warning" id="errorMsgPersonalLayer">
                Personal layers are linked to the connected API at the time of upload. You will not
                be able to load or delete data uploaded locally (when using
                docker-compose-local.yml) on citiwattsdev or vice versa. Send an email
                <a href="mailto:citiwatts@hevs.ch">citiwatts&#64;hevs.ch</a> for more information.
              </p>
            }
          </mat-card-content>
        </mat-card>
      </mat-grid-list>
    </mat-dialog-content>
  </div>
}
