import { Component, inject, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'

import { AuthService } from '@core/services/auth.service'
import { FileManagementComponent } from '../file-management/file-management.component'

@Component({
  selector: 'app-file-management-button',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './file-management-button.component.html',
  styleUrl: './file-management-button.component.css',
})
export class FileManagementButtonComponent implements OnInit {
  readonly dialog = inject(MatDialog)

  isLoggedIn = false

  constructor(private _authService: AuthService) {}

  async ngOnInit(): Promise<void> {
    this.isLoggedIn = await this._authService.isLoggedIn()
  }

  openFileManagement(): void {
    this.dialog.open(FileManagementComponent, {})
  }
}
