@if (this.isAnUpload) {
  <h1 mat-dialog-title>Upload and share personal layer</h1>

  <div mat-dialog-content>
    <p>
      Are you sure you want to share the personal layer <b>{{ this.fileToShare.name }}</b> ?
    </p>
    <p>
      By sharing a layer, you make it accessible to the users you specified. They will be able to
      see and download the layer.
    </p>
  </div>

  <div mat-dialog-actions align="end">
    <button mat-button (click)="close()">Cancel</button>
    <button mat-button (click)="save()" class="uk-text-danger">Upload and Share</button>
  </div>
} @else {
  <h1 mat-dialog-title>Share personal layer</h1>

  <div mat-dialog-content>
    <p>
      Are you sure you want to share the personal layer <b>{{ this.fileToShare.name }}</b> ?
    </p>
    <p>
      By sharing a layer, you make it accessible to the users you specified. They will be able to
      see and download the layer.
    </p>
    <p>Removing a user from the list will revoke their access to the layer.</p>
  </div>

  <div mat-dialog-actions align="end">
    <button mat-button (click)="close()">Cancel</button>
    <button mat-button (click)="save()" class="uk-text-danger">Update</button>
  </div>
}
