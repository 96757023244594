/* ******************************************************************
 * * Copyright         : 2024 HES-SO Valais-Wallis - Institute of Informatics - EASILab
 * * Description       :
 * * Revision History  :
 * * Date           Author                              Comments
 * * ---------------------------------------------------------------------------
 * * 27.05.2017     Lesly Houndole - CREM               Creation
 * *
 ******************************************************************/
import { HttpClient } from '@angular/common/http'
import { Injectable, signal } from '@angular/core'
import { BaseService } from '@bases/base.service'
import { CalculationModuleService } from '@components/_panel-left/cms-tab/calculation-modules/calculation-module.service'
import { PanelLeftService } from '@components/_panel-left/panel-left.service'
import { TABS } from '@components/_panel-left/tabs.data'
import { SidePanelService } from '@components/_panel-right/side-panel/side-panel.service'
import { GEOSERVER_URL, MAX_SURFACE_VALUE_CM } from '@core/constants/constant.data'
import { HECTARE } from '@core/constants/scale.data'
import { Location } from '@core/models/location/location'
import { ScaleLevel } from '@core/models/scale-level.class'
import { Helper } from '@core/services/helper'
import { InteractionService } from '@core/services/interaction.service'
import { LayersService } from '@core/services/layers.service'
import { LoaderService } from '@core/services/loader.service'
import { SelectionScaleService } from '@core/services/selection-scale.service'
import { ToasterService } from '@core/services/toaster.service'
import { isNullOrUndefinedString } from '@services/core.utilities'
import area from '@turf/area'
import { buffer } from '@turf/buffer'
import { polygon } from '@turf/helpers'
import { environment } from 'environments/environment'
import * as L from 'leaflet'
import { BehaviorSubject } from 'rxjs'
import { map } from 'rxjs/operators'
import { SelectionToolUtilsService } from './selection-tool-utils.service'

@Injectable()
export class SelectionToolService extends BaseService {
  nbOfElementsSelected$ = signal<number>(0)
  nbOfDrawnElementSelected$ = signal<number>(0)

  selectedSurface$ = signal<number>(0)

  nutsIdsSubject = new BehaviorSubject<string[]>([]) //@ToDo Gwen

  //@ToDo: transform to signal
  buttonLoadResultStatus = new BehaviorSubject<boolean>(false)
  buttonClearAll = new BehaviorSubject<boolean>(false)
  locationsSubject = new BehaviorSubject<Location[]>([])
  areasSubject = new BehaviorSubject<L.Layer[]>([])
  multiSelectionLayers: L.FeatureGroup = new L.FeatureGroup()
  isPolygonDrawer = false
  isActivate: boolean // todo: check if it was use for hotmaps but no more now

  private _nutsIds: Set<string> = new Set()
  private _controlMultiLayer: L.FeatureGroup = new L.FeatureGroup()
  private _theDrawer
  private _isDrawer = false

  constructor(
    http: HttpClient,
    loaderService: LoaderService,
    toasterService: ToasterService,
    private _helper: Helper,
    private _interactionService: InteractionService,
    private _selectionToolUtilsService: SelectionToolUtilsService,
    private _selectionScaleService: SelectionScaleService,
    private _sidePanelService: SidePanelService,
    private _layerService: LayersService,
    private _calculationModuleService: CalculationModuleService,
    private _panelLeftService: PanelLeftService,
  ) {
    super(http, loaderService, toasterService)
  }

  drawCreated(e, scaleLevel: ScaleLevel) {
    const event: L.DrawEvents.Created = <L.DrawEvents.Created>e
    const layer: any = event.layer
    let controlIntersectContainsHectare = false
    this.isActivate = false
    this.isPolygonDrawer = false
    let location = ''

    // Hectare case
    if (scaleLevel.id === 5) {
      controlIntersectContainsHectare = this._helper.controlDrawerLayer(
        this.multiSelectionLayers,
        layer,
      )
      if (!controlIntersectContainsHectare) {
        location = this._convertPostGisLatLongToLocationString(
          this._helper.getLocationsFromLayer(layer),
        )
        this.drawHectaresLoadingResult(layer)
      }
    } else {
      location = this._convertLatLongToLocationString(this._helper.getLocationsFromLayer(layer))
      this._getNutID(location, scaleLevel.id, scaleLevel.geoServer)
    }
  }

  loadResultNuts() {
    const layerNameArray = []
    for (let i = 0; i < this._layerService.layersArray.keys().length; i++) {
      layerNameArray.push(this._layerService.layersArray.keys()[i])
    }
    this._getStatistics()
    this.setAreas()
  }

  removeLayerFromMultiSelectionLayers(layer: any) {
    // if the nut
    const id_selection = this._selectionToolUtilsService.getSelectionIdFromLayer(layer)
    this._nutsIds.delete(id_selection)
    let indexToRemove = 999
    for (let i = 0; i < this.multiSelectionLayers.getLayers().length; i++) {
      const iDInMultiSelectionLayers = this._selectionToolUtilsService.getSelectionIdFromLayer(
        this.multiSelectionLayers.getLayers()[i],
      )
      if (id_selection === iDInMultiSelectionLayers) {
        indexToRemove = i
        break
      }
    }
    if (indexToRemove !== 999) {
      this.multiSelectionLayers.removeLayer(this.multiSelectionLayers.getLayers()[indexToRemove])

      if (this._controlMultiLayer.getLayers().length > 0) {
        //Check necessary since leaflet 1.8. Otherwise throws error if no layers in group.
        this._controlMultiLayer.removeLayer(this._controlMultiLayer.getLayers()[indexToRemove])
      }
    }
    this._updateSelectionToolAction()
  }

  containLayer(layer: any): number {
    if (!isNullOrUndefinedString(layer._leaflet_id)) {
      if (this._nutsIds.has(this._selectionToolUtilsService.getSelectionIdFromLayer(layer))) {
        return 0
      } else {
        return 1
      }
    } else {
      return 2
    }
  }

  clearAll() {
    this.nbOfElementsSelected$.set(0)

    this._sidePanelService.closeRightPanel()
    // remove all layers selected
    this.multiSelectionLayers.clearLayers()
    this._controlMultiLayer.clearLayers()
    this.selectedSurface$.set(0)
    this.setPanelsBasedOnSurface()

    // remove all nutsID selected
    this._nutsIds.clear()
    this._updateSelectionToolAction()
    this._interactionService.deleteCMTask()
  }

  setPanelsBasedOnSurface() {
    let surface = this.selectedSurface$()
    if (surface != 0 && this._panelLeftService.openedTab$() == null) {
      this._panelLeftService.openedTab$.set(TABS.find((tab) => tab.id == 'tools'))
    }

    if (surface === 0) {
      this._sidePanelService.closeRightPanel
    }
  }

  private _getStatistics() {
    if (
      !(
        this.selectedSurface$() > MAX_SURFACE_VALUE_CM &&
        this._selectionScaleService.currentScaleLevel$().displayName == HECTARE
      )
    ) {
      this._sidePanelService.openRightPanel()
    } else {
      this.toasterService.showToasterSurfaceLimit()
    }
  }

  activateClickSelectionTool() {
    if (this._isDrawer) {
      this._theDrawer.disable() // disable the current drawer before creating a new one
    }
    this.isPolygonDrawer = false
  }

  activateDrawTool(myMap: L.DrawMap, tool: string) {
    if (this._isDrawer) {
      this._theDrawer.disable() // disable the current drawer before creating a new one
    }
    // @ToDo: edit and put in the table
    switch (tool) {
      case 'rectangle':
        this._theDrawer = new L.Draw.Rectangle(myMap, <L.DrawOptions.RectangleOptions>{
          shapeOptions: { showArea: false },
        })
        this.isPolygonDrawer = false
        break
      case 'circle':
        this._theDrawer = new L.Draw.Circle(myMap)
        this.isPolygonDrawer = false
        break

      case 'polygon':
        this._theDrawer = new L.Draw.Polygon(myMap)
        this.isPolygonDrawer = true
        break
      default:
        break
    }
    this._theDrawer.enable()

    this._isDrawer = true
    //this.isPolygonDrawer = true;
  }

  private _getNutID(location, scaleLevel, stringLayerType) {
    this.loaderService.display(true)
    const epsg = '4326'
    const coordinate = location
    let url =
      GEOSERVER_URL +
      '?service=wfs' +
      '&version=2.0.0' +
      '&request=GetFeature' +
      '&srsName=EPSG:' +
      epsg +
      '&typeNames=' +
      environment.prefixWorkspaceName +
      stringLayerType +
      '&outputFormat=application/json' +
      '&CQL_FILTER= ' +
      environment.nuts_CQL_FILTER +
      coordinate.toString() +
      '))))'
    if (scaleLevel < 4) {
      url += 'AND stat_levl_=' + scaleLevel + 'AND date=2015-01-01Z'
    }

    this.GET(url)
      .pipe(map((res: Response) => res as any))
      .subscribe(
        (res) => this._drawResultBeforeLoadingResult(res),
        (err) => super.handleError(err),
      )
  }

  private _updateSelectionToolAction() {
    if (this._nutsIds.size > 0) {
      this._setButtonsSelectionToolState(true)
      this._defineSurface(this.multiSelectionLayers)
    } else {
      // disable buttons after clear
      this._setButtonsSelectionToolState(false)
      this._defineSurface(this.multiSelectionLayers)
    }
    this.nbOfElementsSelected$.set(this._nutsIds.size)
    this.nutsIdsSubject.next(Array.from(this._nutsIds))
  }

  private _setButtonsSelectionToolState(value) {
    this.buttonLoadResultStatus.next(value)
    this.buttonClearAll.next(value)
  }

  private _updateSelectionToolActionHectare() {
    if (this.areasSubject.getValue().length > 0) {
      this._setButtonsSelectionToolState(true)
      this._defineSurface(this._controlMultiLayer)
    } else {
      // disable buttons after clear
      this._setButtonsSelectionToolState(false)
    }
  }

  private _defineSurface(layerGroup) {
    let surface = 0

    const geoJson = layerGroup.toGeoJSON()

    if (geoJson && geoJson.features) {
      geoJson.features.forEach((feature) => {
        surface += area(feature)
      })
    }

    surface = Math.round(surface / 1_000_000)

    if (surface >= MAX_SURFACE_VALUE_CM) {
      this._calculationModuleService.panelIsOpen.next(false)
      if (this._selectionScaleService.currentScaleLevel$().displayName === HECTARE) {
        this._sidePanelService.closeRightPanel()
      }
      this.toasterService.showToasterSurfaceLimit()
    }

    this.selectedSurface$.set(surface)
    this.setPanelsBasedOnSurface()
  }

  setAreas() {
    this.areasSubject.next(this.multiSelectionLayers.getLayers())
  }

  private _setLayerDependingCircleForControl(layer) {
    let layerInMultiSelection
    if (layer instanceof L.Circle) {
      layerInMultiSelection = L.polygon([this._helper.getLocationsFromCircle(layer)])
    } else {
      layerInMultiSelection = layer
    }

    layerInMultiSelection._leaflet_id = layer._leaflet_id
    return layerInMultiSelection
  }

  drawHectaresLoadingResult(layer: any) {
    if (this.multiSelectionLayers.hasLayer(layer) === false) {
      const layerTemp = this._setLayerDependingCircleForControl(layer)
      this._controlMultiLayer.addLayer(layerTemp)
      this.multiSelectionLayers.addLayer(layer)
      this.areasSubject.next(this.multiSelectionLayers.getLayers())
      this.nbOfElementsSelected$.set(this.areasSubject.getValue().length)
      this._updateSelectionToolActionHectare()
      this.loaderService.display(false)

      // @ToDo fix the selection of hectare area (to delete)
      layer.on('click', () => {
        if (layer.options.fillColor === null) {
          layer.setStyle({
            fillColor: 'red',
          })
          this.nbOfDrawnElementSelected$.set(this.nbOfDrawnElementSelected$() + 1)
        } else {
          layer.setStyle({
            fillColor: null,
          })
          this.nbOfDrawnElementSelected$.set(this.nbOfDrawnElementSelected$() - 1)
        }
      })
    }
  }

  private _drawResultBeforeLoadingResult(result: any) {
    if (isNullOrUndefinedString(result) === false) {
      for (const feature of result.features) {
        const selectionID = this._helper.getSelectionIDFromGeoJsonLayer(feature)

        if (!this._nutsIds.has(selectionID)) {
          this._nutsIds.add(selectionID)
          const areaNutsSelectedLayer = L.geoJson(feature)
          this.multiSelectionLayers.addLayer(areaNutsSelectedLayer)
        }
      }
    }
    this._updateSelectionToolAction()
    this.loaderService.display(false)
  }

  addToMultiSelectionLayers(layer: any) {
    if (isNullOrUndefinedString(layer) === false) {
      const selection_id = this._selectionToolUtilsService.getSelectionIdFromLayer(layer)
      if (this._nutsIds.has(selection_id) === false) {
        this._nutsIds.add(selection_id)
        this.multiSelectionLayers.addLayer(layer)
        this._updateSelectionToolAction()
      }
    }
  }

  addHectareToMultiSelectionLayers(layer: any) {
    if (isNullOrUndefinedString(layer) === false) {
      this.multiSelectionLayers.addLayer(layer)
    }
  }

  deleteSelectedAreas() {
    this.multiSelectionLayers.getLayers().map((layer: any) => {
      if (layer.options.fillColor === 'red') {
        this.multiSelectionLayers.removeLayer(layer)
        this._controlMultiLayer.removeLayer(layer)
      }
    })
    this.nbOfElementsSelected$.set(0)
    this.nbOfDrawnElementSelected$.set(0)
    this.setAreas()
    this.nbOfElementsSelected$.set(this.areasSubject.getValue().length)
    this._defineSurface(this._controlMultiLayer)
  }

  drawSelectionShapeFromFile(geoJson: any) {
    const gapDistance = 0.01

    geoJson.features.forEach((feature) => {
      const geom = feature.geometry
      if (geom.type == 'MultiPolygon') {
        geom.coordinates.forEach((coord) => {
          const poly = L.polygon(L.GeoJSON.coordsToLatLngs(coord[0]))
          this.drawHectaresLoadingResult(poly)
        })
      } else if (geom.type == 'Polygon') {
        const polygonGeoJSON = polygon(geom.coordinates)
        const bufferedFeature = buffer(polygonGeoJSON, -gapDistance, { units: 'meters' })
        const latLong = L.GeoJSON.coordsToLatLngs(bufferedFeature.geometry.coordinates, 1)
        let poly = L.polygon(latLong)
        this.drawHectaresLoadingResult(poly)
      } else {
        console.warn('Shape not supported:', geom.type)
      }
    })
  }

  private _convertPostGisLatLongToLocationString(latlng): string {
    let n = 0
    let locations = ''
    do {
      const loc = latlng[n].lng + ' ' + latlng[n].lat + ','
      locations = locations + loc
      n++
    } while (!isNullOrUndefinedString(latlng[n]))

    const loc = latlng[0].lng + ' ' + latlng[0].lat
    locations = locations + loc
    return locations
  }

  private _convertLatLongToLocationString(latlng): string {
    let n = 0
    let locations = ''
    do {
      const loc = latlng[n].lat + ' ' + latlng[n].lng + ','
      locations = locations + loc
      n++
    } while (!isNullOrUndefinedString(latlng[n]))

    const loc = latlng[0].lat + ' ' + latlng[0].lng
    locations = locations + loc
    return locations
  }
}
