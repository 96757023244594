import { Component, inject, OnInit } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatIconModule } from '@angular/material/icon'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { UserManagementService } from '@components/_header-bar/user-management/user-management.service'
import { ChartComponent } from '@components/chart/chart.component'
import { AuthService } from '@core/services/auth.service'
import { environment } from '@env/environment'
import { ChartOptions } from 'chart.js'
import { UploadComponent } from './upload/upload.component'

@Component({
  standalone: true,
  selector: 'app-file-management',
  templateUrl: './file-management.component.html',
  styleUrls: ['./file-management.component.scss'],
  providers: [UserManagementService],
  imports: [
    // Components
    ChartComponent,
    UploadComponent,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatGridListModule,
    MatCardModule,
    MatProgressBarModule,
  ],
})
export class FileManagementComponent implements OnInit {
  readonly dialogRef = inject(MatDialogRef<FileManagementComponent>)

  isLoggedIn = false

  firstname = ''
  lastname = ''

  diskSpaceUsed = 0
  diskSpaceMax = 0
  diskSpaceLabels = ['']
  diskSpaceDataset
  diskSpaceOptions: ChartOptions = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    maintainAspectRatio: false,
    indexAxis: 'y',
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            return (
              context.dataset.label +
              ': ' +
              context.dataset.data[context.dataIndex].toString().substring(0, 6) +
              ' MB'
            )
          },
        },
      },
    },
  }

  localhost: boolean = environment.apiUrl.includes('localhost')

  constructor(
    private _userManagementService: UserManagementService,
    private _authService: AuthService,
  ) {}

  ngOnInit(): void {
    this._fetchInformation()
  }

  _getAccountInformation() {
    this._getUserInformation()
    this._userManagementService.getDiskSpace().then((data) => {
      this.diskSpaceMax = data.max_size
      this.diskSpaceUsed = data.used_size
      this.diskSpaceDataset = [
        {
          label: 'Account disk',
          backgroundColor: this.getColorsBasedOnDiskUsage([this.diskSpaceUsed]),
          data: [this.diskSpaceUsed, this.diskSpaceMax - this.diskSpaceUsed],
        },
      ]
    })
  }

  updateDiskSpace() {
    this._getAccountInformation()
  }

  private getColorsBasedOnDiskUsage(diskSpaceUsed: number[]): string[] {
    return diskSpaceUsed.map((value) => {
      if (value < 50) {
        return '#50C878'
      } else if (value < 250) {
        return '#F28C28'
      } else {
        return '#D22B2B'
      }
    })
  }

  private async _fetchInformation() {
    this.isLoggedIn = await this._authService.isLoggedIn()

    if (this.isLoggedIn) {
      this._getAccountInformation()
    }
  }

  private _getUserInformation() {
    this.firstname = this._authService.getFirstname()
    this.lastname = this._authService.getLastname()
  }
}
