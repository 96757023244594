import { environmentCoolLifeBase } from '@env/environment.coollife'

const environmentCoolLifeDev = {
  ...environmentCoolLifeBase,
  apiUrl: 'https://api.citiwattsdev.hevs.ch/api',
  geoserverUrl: 'https://geoserver.citiwattsdev.hevs.ch/geoserver/hotmaps/wms',
  keycloakUrl: 'https://auth.citiwattsdev.hevs.ch',
  keycloakRealm: 'citiwatts-dev',
  matomoTrackerUrl: 'https://analytics.citiwattsdev.hevs.ch/',
  matomoSiteId: '2',
  // !!! Dates must be set using UTC time. They are later converted to locale time
  maintenanceNotice: '', // Set to '' if no maintenance is scheduled or the dates of the maintenance eg. '2025-01-20 07:00 - 2025-01-21 17:00' FORMAT IS IMPORTANT, end date is included.  
}

export const environment = environmentCoolLifeDev
