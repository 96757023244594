import { ChangeDetectionStrategy, Component } from '@angular/core'
import { environment } from '@env/environment'
import { CMsTabComponent } from './cms-tab/cms-tab.component'
import { LayersTabComponent } from './layers-tab/layers-tab.component'
import { PanelLeftService } from './panel-left.service'
import { ScenarioTabComponent } from './scenario-tab/scenario-tab.component'
import { Tab } from './tab.class'
import { TABS } from './tabs.data'
import { ToolsTabComponent } from './tools-tab/tools-tab.component'

@Component({
  selector: 'app-panel-left',
  standalone: true,
  imports: [
    // Components
    ToolsTabComponent,
    CMsTabComponent,
    LayersTabComponent,
    ScenarioTabComponent,
  ],
  templateUrl: './panel-left.component.html',
  styleUrl: './panel-left.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PanelLeftComponent {
  toolTab: Tab = TABS.find((tab) => tab.id == 'tools')
  layerTab: Tab = TABS.find((tab) => tab.id == 'layers')

  tabs: Tab[] = [this.toolTab, this.layerTab] // Other tabs are add in ngOnInit()

  constructor(public panelLeftService: PanelLeftService) {}

  ngOnInit() {
    if (environment.cmsId.length > 0) {
      this.tabs.push(TABS.find((tab) => tab.id == 'cms'))
    }

    if (environment.scenarioExplorer !== '') {
      this.tabs.push(TABS.find((tab) => tab.id == 'scenario'))
    }
  }
}
