import { CommonModule, NgStyle } from '@angular/common'
import { Component, EventEmitter, Input, Output } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { ScenarioParameters } from '@components/_panel-right/results-tab/result-manager/result-manager'
import { isNullOrUndefinedString } from '@core/services/core.utilities'
import { MapComponent } from '@pages/map/map.component'
import { MapService } from '@pages/map/services/map.service'
import chroma from 'chroma-js'
import * as L from 'leaflet'
import { BehaviorSubject } from 'rxjs'
import { SelectionToolService } from '../tools-tab/selection-tools/service/selection-tool.service'
import { ModelDefinition, ScenarioService } from './scenario.service'

@Component({
  standalone: true,
  selector: 'app-scenario-tab',
  templateUrl: './scenario-tab.component.html',
  styleUrl: './scenario-tab.component.css',
  imports: [
    NgStyle,
    CommonModule, // is mandatory of | async ?

    // Form
    FormsModule,
  ],
  providers: [ScenarioService],
})
export class ScenarioTabComponent {
  @Input() scenarioOpen: boolean
  @Output() emitClose = new EventEmitter<void>()

  //@ToDo change in signal
  public apiScenarioRespond$: BehaviorSubject<boolean> = new BehaviorSubject(true) // used to hide everything in case of api failure
  public isModelDetailsLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true) // used to disable model selection and show main loader
  public isDataLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false) // used to disable all selection and show loader in "visualize data" button
  public hasInternalAccess$: BehaviorSubject<boolean> = new BehaviorSubject(true) // used to enable password prompt when user is not allowed.
  public showLegend$: BehaviorSubject<boolean> = new BehaviorSubject(false)
  public errorMessage$: BehaviorSubject<string> = new BehaviorSubject('')
  public internalPasswordMessage$: BehaviorSubject<string> = new BehaviorSubject('')
  public allowRetry$: BehaviorSubject<boolean> = new BehaviorSubject(true)

  public modelsList: string[]
  public definition: ModelDefinition
  public modelSelected: string
  public variableSelected: string
  public regionSelected: string
  public yearSelected: number
  public internalPasswordValue: string
  private _minColor = '#f7f7f7'
  private _maxColor = '#0c3b19'
  public legendContent = [
    { color: this._minColor, label: '0' },
    { color: '#cfbeba', label: '25%' },
    { color: '#9d8b77', label: '50%' },
    { color: '#5d613d', label: '75%' },
    { color: this._maxColor, label: '100%' },
  ]

  private _scenarioPane

  constructor(
    public mapComponent: MapComponent,
    public _mapService: MapService,
    private _scenarioService: ScenarioService,
    private _selectionToolService: SelectionToolService,
  ) {}

  ngOnInit() {
    this.loadModels()
  }

  loadModels() {
    this.errorMessage$.next('')
    this.apiScenarioRespond$.next(true)

    this._scenarioService.getModels().subscribe({
      next: (result) => {
        try {
          this.modelsList = result.models
          this.modelSelected = this.modelsList[0]
          this.selectModel()
          this.hasInternalAccess$.next(true)
        } catch (error) {
          this.apiScenarioRespond$.next(false)
          this.errorMessage$.next(
            'Please enter the secret password to access the internal scenario explorer.',
          )
          this.hasInternalAccess$.next(false)
        }
      },
      error: (error) => {
        this.apiScenarioRespond$.next(false)
        if (error.status === 401) {
          this.errorMessage$.next(
            'You need to be signed in to access the internal scenario explorer.',
          )
          this.allowRetry$.next(false)
        } else {
          this.errorMessage$.next('OpenEntrance API did not respond. Try again later.')
        }
        console.warn('Scenario api failure, load models', error)
      },
    })
  }

  submitInternalScenarioPassword() {
    this._scenarioService.submitInternalScenarioPassword(this.internalPasswordValue).subscribe({
      next: (result) => {
        this.internalPasswordMessage$.next(result.status)
        if (result.status == 'Internal Access Granted') {
          // Refresh the component
          this.loadModels()
        }
      },
      error: () => {
        this.internalPasswordMessage$.next(
          'An error occured when trying to access scenario explorer.',
        )
      },
    })
  }

  selectModel() {
    this.isModelDetailsLoading$.next(true)
    this.clearMap()

    this._scenarioService.getDefinitions(this.modelSelected).subscribe({
      next: (definition) => {
        this.definition = definition

        this.variableSelected = definition.variables[0]
        this.regionSelected = definition.regions[0]
        this.yearSelected = definition.years[0]

        this.isModelDetailsLoading$.next(false)
      },
      error: (error) => {
        console.warn('Scenario api failure, selectModel', error)
        this.isModelDetailsLoading$.next(false)
        this.errorMessage$.next('This model has no definition. Try another.')
      },
    })
  }

  getData() {
    this.isDataLoading$.next(true)
    this.clearMap()

    this._scenarioService
      .getData(this.modelSelected, this.variableSelected, this.regionSelected, this.yearSelected)
      //@ToDo: add a timemout
      .subscribe({
        next: (data) => {
          this.isDataLoading$.next(false)

          if (data.features.length == 0) {
            this.errorMessage$.next('No result with these parameters.')
            return
          }

          this.showLegend$.next(true)
          this.errorMessage$.next('')

          // Extract all values from properties
          var values = data.features.map((feature) => feature.properties.value)

          // Determine the minimum and maximum values
          var minValue = Math.round(Math.min(...values))
          var maxValue = Math.round(Math.max(...values))
          var unit = data.features[0].properties.unit

          if (minValue == maxValue) {
            this.legendContent = [
              {
                color: this._maxColor,
                label: maxValue.toString() + ' ' + unit,
              },
            ]
          } else {
            var quarter = Math.round(((maxValue - minValue) * 25) / 100)
            var half = Math.round(((maxValue - minValue) * 50) / 100)
            var threequarters = Math.round(((maxValue - minValue) * 75) / 100)

            this.legendContent = [
              {
                color: this._minColor,
                label: minValue.toString() + ' ' + unit,
              },
              { color: '#cfbeba', label: quarter.toString() + ' ' + unit },
              { color: '#9d8b77', label: half.toString() + ' ' + unit },
              {
                color: '#5d613d',
                label: threequarters.toString() + ' ' + unit,
              },
              {
                color: this._maxColor,
                label: maxValue.toString() + ' ' + unit,
              },
            ]
          }
          // Define a linear color scale from light green to dark green
          var colorScale = chroma
            .scale([this._minColor, this._maxColor])
            .domain([minValue, maxValue])

          let scenarioParameters: ScenarioParameters = {
            model: this.modelSelected,
            variable: this.variableSelected,
            region: this.regionSelected,
            year: this.yearSelected,
          }

          this.mapComponent.scenarioResults = {
            data: data.features,
            scenarioParameters: scenarioParameters,
            layers: [],
            no_data_layers: [],
          }

          // Add GeoJSON layer to the map with style function
          this._scenarioPane = L.geoJSON(data as any, {
            style: function (feature) {
              // Get the value from properties
              var value = feature.properties.value

              // Calculate the color based on the value
              var color = colorScale(value).hex()

              // Return style object
              return {
                fillColor: color,
                color: 'black',
                weight: 1,
                opacity: 1,
                fillOpacity: 0.7,
              }
            },
          })
          this._scenarioPane.addTo(this._mapService.getMap())
        },
        error: (error) => {
          console.warn('Scenario api failure', error)
          this.errorMessage$.next('Unexpected error has occurred.')
          this.isDataLoading$.next(false)
        },
      })
  }

  public clearMap() {
    this.errorMessage$.next('')
    this.showLegend$.next(false)
    this._selectionToolService.clearAll()
    if (!isNullOrUndefinedString(this._scenarioPane))
      this._mapService.getMap().removeLayer(this._scenarioPane)
  }
}
