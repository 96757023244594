import { Pipe, PipeTransform } from '@angular/core'
import { BusinessNameService } from '@core/services/business.service'

@Pipe({
  standalone: true,
  name: 'layerName',
})
export class LayerNamePipe implements PipeTransform {
  constructor(private _businessNameService: BusinessNameService) {}

  transform(value: any): any {
    if (value === null) {
      return 'Nothing to transform'
    }
    const valueTransformed = this._businessNameService.getReadableLayerName(value)

    return valueTransformed
  }
}
