import { Injectable } from '@angular/core'
import { LAYERS_ARRAY } from '@core/constants/layers.data'
import { LAYERS_INDICATORS_BUSINESS_NAME } from '../constants/layers-indicators-business-name.data'

@Injectable()
export class BusinessNameService {
  getReadableIndicatorsName(name: string): string {
    return LAYERS_INDICATORS_BUSINESS_NAME.find((x) => x.api_name === name)?.business_name || name
  }

  getReadableLayerName(name: string): string {
    return LAYERS_ARRAY.find((x) => x.workspaceName === name)?.name || name
  }
}
