import { Component, Inject } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog'
import { UploadedFile } from '@core/models/upload.class'

@Component({
  selector: 'app-layer-delete-confirm-popup',
  standalone: true,
  imports: [
    //Material
    MatDialogModule,
    MatButtonModule,
  ],
  templateUrl: './layer-delete-confirm-popup.component.html',
  styleUrl: './layer-delete-confirm-popup.component.css',
})
export class LayerDeleteConfirmPopupComponent {
  fileToDelete: UploadedFile
  constructor(
    private _dialogRef: MatDialogRef<LayerDeleteConfirmPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { fileToDelete: UploadedFile },
  ) {
    this.fileToDelete = data.fileToDelete // Assign value from dialog data
  }
  save() {
    this._dialogRef.close(true)
  }

  close() {
    this._dialogRef.close(false)
  }
}
