import { CommonModule, DecimalPipe } from '@angular/common'
import { Component, computed, effect, OnInit, signal } from '@angular/core'
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatDialog, MatDialogModule } from '@angular/material/dialog'
import { MatDividerModule } from '@angular/material/divider'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatSelectModule } from '@angular/material/select'
import { MatTableModule } from '@angular/material/table'
import { MatTooltipModule } from '@angular/material/tooltip'
import { LayerClass } from '@components/_panel-left/layers-tab/layers-interaction/layers-interaction.class'
import { BaseClass } from '@core/bases/base.class'
import { UploadedFile } from '@core/models/upload.class'
import { LayersService } from '@core/services/layers.service'
import { UploadService } from '@core/services/upload.service'
import { environment } from '@env/environment'
import {
  EmailErrorStateMatcher,
  multipleEmailsValidator,
} from '@shared/others/multiple-email-validator'
import { FormatSharedWithPipe } from '@shared/pipes/format-shared-with.pipe'
import { MaterialFileInputModule } from 'ngx-custom-material-file-input'
import { takeUntil } from 'rxjs'
import { FileManagementComponent } from '../file-management.component'
import { LayerDeleteConfirmPopupComponent } from './layer-delete-confirm-popup/layer-delete-confirm-popup.component'
import { LayerSharedConfirmPopupComponent } from './layer-shared-confirm-popup/layer-shared-confirm-popup.component'

@Component({
  standalone: true,
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
  imports: [
    CommonModule,
    DecimalPipe, // used in .ts
    //Forms
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    // Material
    MatTooltipModule,
    MatIconModule,
    MatTableModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MaterialFileInputModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    MatCardModule,
  ],
})
export class UploadComponent extends BaseClass implements OnInit {
  emailShareList$ = signal('')
  parsedEmailList = computed(() => {
    const emailList = this.emailShareList$() || ''
    return emailList
      .split(';')
      .map((email) => email.trim())
      .filter((email) => email.length > 0)
  })

  selectedFileToUpdate$ = signal<UploadedFile | null>(null)
  hasEmailListChanged$ = computed(() => {
    return !(
      this.emailShareList$() ==
      this._formatSharedWithPipe.transform(this.selectedFileToUpdate$().shared_with)
    )
  })

  layers$ = this._layerService.layers$.asReadonly()
  groupedLayers$ = computed(() => {
    const layers = this.layers$()
    const groupsMap = new Map<string, LayerClass[]>()
    for (const layer of layers) {
      if (!groupsMap.has(layer.category)) {
        groupsMap.set(layer.category, [])
      }
      groupsMap.get(layer.category)!.push(layer)
    }
    const result: Array<{ category: string; layers: LayerClass[] }> = []
    for (const [category, layersInCategory] of groupsMap.entries()) {
      result.push({ category, layers: layersInCategory })
    }

    return result
  })

  localhost: boolean = environment.apiUrl.includes('localhost')

  file2Up: File
  isFileOk: boolean = false
  shared: boolean = false //@todo check: shared is deprecated but may still be required by backend
  isUploading: boolean = false // Temporary until api do this async
  uploadedFiles: UploadedFile[] = [] //@todo should be in service and signal

  layerTypeControl = new FormControl('')
  emailFormControl = new FormControl('', [multipleEmailsValidator()])
  editEmailFormControl = new FormControl('', [multipleEmailsValidator()])
  fileControl: FormControl
  matcher = new EmailErrorStateMatcher()

  displayedColumns = ['filename', 'layer', 'size', 'share', 'actions']
  selectedFileName: string = ''
  multiple: boolean = false
  localhostErrorMessage = "Layers can't be shared when the API (backend) is locally hosted"
  emailListToolTip =
    'Write email addresses separated by a semicolon ` ; ` to make this layer accessible to other users. Removing a user from the list will remove their access to the layer.'

  files //@todo check why multiple files in the input thingy?

  constructor(
    private _uploadService: UploadService,
    private _layerService: LayersService,
    private _fileManagementComponent: FileManagementComponent,
    private _formatSharedWithPipe: FormatSharedWithPipe,
    private _dialog: MatDialog,
  ) {
    super()
    this.fileControl = new FormControl(this.files, [
      Validators.required,
      // MaxValidator(this.maxSize * 1024)
    ])
    effect(
      () => {
        if (this.selectedFileToUpdate$() != null) {
          const prefilledString = this._formatSharedWithPipe.transform(
            this.selectedFileToUpdate$().shared_with,
          )
          this.emailFormControl.setValue(prefilledString ?? '')
          this.editEmailFormControl.setValue(prefilledString ?? '')
        } else {
          this.emailFormControl.setValue('')
          this.editEmailFormControl.setValue('')
        }
      },
      {
        allowSignalWrites: true,
      },
    )
  }

  selectFileToUpdate(data: UploadedFile) {
    this.selectedFileToUpdate$.set(data)
  }

  cancelLayerUpdate() {
    this.selectedFileToUpdate$.set(null)
    this.emailShareList$.set('')
  }

  ngOnInit() {
    this.fileControl.valueChanges.subscribe((fileInput) => {
      const files = fileInput && fileInput.files ? fileInput.files : null
      if (files && files.length > 0) {
        this.file2Up = files[0]
        this.isFileOk = true
      } else {
        this.file2Up = null
        this.isFileOk = false
      }
    })

    this.emailFormControl.valueChanges.subscribe((newValue: string) => {
      this.emailShareList$.set(newValue)
    })

    this.editEmailFormControl.valueChanges.subscribe((newValue: string) => {
      this.emailShareList$.set(newValue)
    })
    if (this.localhost) {
      this.emailFormControl.disable()
      this.editEmailFormControl.disable()
    }
    this._uploadService.getUserPersonalAndSharedLayers()

    this._uploadService.uploadedFiles.pipe(takeUntil(this._unsubscribe$)).subscribe((files) => {
      this.uploadedFiles = files //@todo not good
        .map((file) => {
          if (this._uploadService.filterPersonalOrShareLayerWithTypeInProject(file)) {
            return { ...file, layerName: this._getLayerName(file) }
          }
        })
        .filter((layer) => layer)
    })
  }

  private _getLayerName(layer: UploadedFile): string {
    if (layer.layer_type == 'heat2') {
      return 'Heat density total (future)' //@ToDo: Fix this hardcode workaround for heat2
    } else {
      for (let i in this.layers$()) {
        if (this.layers$()[i].workspaceName == layer.layer) {
          return this.layers$()[i].name
        }
      }
      return layer.layer // @ToDo, why is set as workspace when sending and stored in db but here "layer" ?
    }
  }

  getFiles() {
    this._uploadService.getUserPersonalAndSharedLayers()
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      this.file2Up = event.target.files[0]
      this.isFileOk = true
    } else {
      this.isFileOk = false
    }
  }

  fileUpload() {
    const selectedLayerName = this.layerTypeControl.value

    if (!(this.isFileOk || !selectedLayerName)) return

    if (this.file2Up.size / 1000 >= 10000) {
      alert('Uploaded files cannot be bigger than 10MB / 10000KB')
      this.isFileOk = false
      return
    }

    if (this.file2Up.size / 1000 >= 800 && this.file2Up.size / 1000 <= 10000) {
      //Ask user to confirm when uploading potential large files.
      if (
        !confirm(
          'Your file may cover a large area, and may take up to some minutes to generate. Would you like to continue anyway?',
        )
      )
        return
    }

    const foundLayer = this.layers$().find((l) => l.name === selectedLayerName)
    let layerTypePayload: any
    //@todo remove this work around when GeoJSON layers are implemented as a Type
    if (selectedLayerName == 'Geojson') {
      layerTypePayload = {
        workspaceName: 'Geojson',
        layer_type: 'Geojson',
      }
    } else if (foundLayer) {
      layerTypePayload = {
        workspaceName: foundLayer.workspaceName,
        layer_type: foundLayer.layer_type,
      }
    }

    const emails = this.parsedEmailList()

    this.isUploading = true
    this._uploadService
      .add(this.file2Up, String(this.shared), layerTypePayload, emails)
      .then((success) => {
        if (success) {
          this.isUploading = false
          this._fileManagementComponent.updateDiskSpace()
          this.file2Up = null
          this.isFileOk = false
          this.layerTypeControl.reset()
          this.emailFormControl.reset()
          this.fileControl.reset()
        } else this.isFileOk = true
        this.isUploading = false
      })
  }

  async updateListShare() {
    this.isUploading = true
    const currentLayerID = this.selectedFileToUpdate$().id
    const emails = this.parsedEmailList()

    await this._uploadService.update(currentLayerID, emails)
    await this._uploadService.getUserPersonalAndSharedLayers()

    const updatedLayer = this.uploadedFiles.find((file) => file.id == currentLayerID)
    this.selectedFileToUpdate$.set(updatedLayer)
    this.isUploading = false
  }

  download(upFile: UploadedFile) {
    this._uploadService.download(upFile).then((url) => {
      if (url === '') return
      // window.open(url); //POPUP blocker
      const a = document.createElement('a')
      a.href = url
      a.download = upFile.name
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      URL.revokeObjectURL(url)
    })
  }

  openUploadDeletionConfirmationDialog(id, uploadedFile): void {
    const dialogRef = this._dialog.open(LayerDeleteConfirmPopupComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: '400px',
      data: { fileToDelete: uploadedFile },
    })

    dialogRef.afterClosed().subscribe((data) => {
      if (data) this._delete(id)
    })
  }

  // @todo should receive a "prototype" of an uploadfile or use one from the from
  openUploadSharedConfirmationDialog(): void {
    //if no sharelist, just upload
    if (this.parsedEmailList().length == 0) {
      this.fileUpload()
      return
    }

    const dialogRef = this._dialog.open(LayerSharedConfirmPopupComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: '400px',
      // data: { fileToShare: uploadedFile },
      data: { isAnUpload: true, fileToShare: {} },
    })

    dialogRef.afterClosed().subscribe((data) => {
      if (data) this.fileUpload()
    })
  }

  // @todo should receive a "prototype" of an uploadfile or use one from the from
  openUpdateSharedConfirmationDialog(): void {
    if (this.parsedEmailList().length == 0 && !this.hasEmailListChanged$()) {
      this.fileUpload()
      return
    }

    const dialogRef = this._dialog.open(LayerSharedConfirmPopupComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: '400px',
      data: { isAnUpload: false, fileToShare: this.selectedFileToUpdate$() },
    })

    dialogRef.afterClosed().subscribe((data) => {
      if (data) this.updateListShare()
    })
  }

  private _delete(id: number | UploadedFile) {
    this._uploadService.delete(id)
    this.selectedFileToUpdate$.set(null)
    this.emailShareList$.set('')
    this._fileManagementComponent.updateDiskSpace()
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement
    if (input.files && input.files.length > 0) {
      this.selectedFileName = input.files[0].name
    }
  }

  emailListTooltipMessage() {
    if (this.localhost) {
      return this.localhostErrorMessage
    }
    return this.emailListToolTip
  }
}
