import { Component, Input, OnInit, signal } from '@angular/core'
import { MatTooltipModule } from '@angular/material/tooltip'
import { SelectionToolService } from '@components/_panel-left/tools-tab/selection-tools/service/selection-tool.service'
import { CALCULATION_MODULE_CATEGORY, URL_LEGEND } from '@core/constants/constant.data'
import { LAYERS_ARRAY, RASTER_TYPE, VECTOR_TYPE } from '@core/constants/layers.data'
import { HECTARE, LAU2, NUTS0, NUTS1, NUTS2, NUTS3 } from '@core/constants/scale.data'
import { AuthService } from '@core/services/auth.service'
import { SelectionScaleService } from '@core/services/selection-scale.service'
import { ToasterService } from '@core/services/toaster.service'
import { UploadService } from '@core/services/upload.service'
import { LayerClass } from '../../layers-interaction.class'
import { CustomSymbologyComponent } from './custom-symbology/custom-symbology.component'

@Component({
  standalone: true,
  selector: 'app-layer-tool',
  templateUrl: './layer-tool.component.html',
  styleUrls: ['./layer-tool.component.css'],
  imports: [
    // Material
    MatTooltipModule,

    // Component
    CustomSymbologyComponent,
  ],
})
export class LayerToolComponent implements OnInit {
  @Input() layer: LayerClass

  layerType$ = signal<string>('')
  workspaceName$ = signal<string>('')
  allowDownload$ = signal<boolean>(false)
  isLoading$ = signal<boolean>(false)

  imageUrl = URL_LEGEND
  isLegendDisplayed = false
  isInfoDisplayed = false
  displayLegend = false
  isLoggedIn = false
  calculation_module_name = CALCULATION_MODULE_CATEGORY

  constructor(
    private _uploadService: UploadService,
    private _authService: AuthService,
    private _toasterService: ToasterService,
    private _selectionScaleService: SelectionScaleService,
    private _selectionToolService: SelectionToolService,
  ) {}

  async ngOnInit() {
    this.isLoggedIn = await this._authService.isLoggedIn()
    if (this.layer) {
      this.layerType$.set(this.layer.layer_type != undefined ? this.layer.layer_type : '')
      this.workspaceName$.set(this.layer.workspaceName != undefined ? this.layer.workspaceName : '')
    }

    // display download button according to the following rules
    if (this._selectionToolService.buttonLoadResultStatus) {
      this._selectionToolService.buttonLoadResultStatus.subscribe((value) => {
        let availableScales = []
        if (this.layer.category === CALCULATION_MODULE_CATEGORY) {
          availableScales = [NUTS0, NUTS1, NUTS2, NUTS3, LAU2, HECTARE]
        } else if (this.layer.dataType) {
          if (this.layer.dataType === RASTER_TYPE) {
            availableScales = [NUTS3, LAU2, HECTARE]
          } else if (this.layer.dataType === 'shp') availableScales = [NUTS2, NUTS3, LAU2, HECTARE]
          else if (this.layer.dataType === 'csv')
            availableScales = this.layer.scales ? this.layer.scales : [NUTS2, NUTS3, LAU2, HECTARE]
          else throw Error('This dataType is not supported.')
        } else {
          console.warn("This layer can't be downloaded: ", this.layer.name)
        }

        this.allowDownload$.set(
          value &&
            availableScales.indexOf(this._selectionScaleService.currentScaleLevel$().displayName) >
              -1,
        )
      })
    }
  }

  toggleLegend() {
    this.isLegendDisplayed = !this.isLegendDisplayed
  }
  toggleInformation() {
    this.isInfoDisplayed = !this.isInfoDisplayed
  }

  endLoadLegend() {
    this.displayLegend = true
  }

  export() {
    this.isLoading$.set(true)
    this._uploadService.export(this.layer).then((data) => {
      if (data.url != '') {
        //window.open(data.url); //POPUP blocker
        const a = document.createElement('a')
        a.href = data.url
        a.download = data.filename
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        URL.revokeObjectURL(data.url)
      }
      this.isLoading$.set(false)
    })
  }

  async saveInPersonalLayer() {
    // No security for bad layer uploaded. If a bad layer is uploaded, you must go by hand in database to delete it in order to fix errors in frontend. This is a second security after hiding the button in frontend.
    if (this.layer.workspaceName == undefined || this.layer.layer_type == undefined) {
      this._toasterService.showDangerToaster('This layer type is not known')
      return
    }

    // Check that workspaceName define by CM developers exist in layer list
    let workspaceNameExists = LAYERS_ARRAY.some(
      (layer) => this.layer.workspaceName === layer.workspaceName,
    )
    let layerTypeExists = LAYERS_ARRAY.some((layer) => this.layer.layer_type === layer.layer_type)
    if (!workspaceNameExists || !layerTypeExists) {
      console.warn('This CM layer cannot be saved: ', this.layer)
      this._toasterService.showDangerToaster('This layer type is not known')
      return
    }

    this._uploadService.export(this.layer).then(async (data) => {
      // Transform the generate layer into a file
      let response = await fetch(data.url)
      let blob = await response.blob()

      // In order to use the CM session name as file name, must check type
      // the extension can be also found with data.filename
      // @ToDo: metadata seems not useful
      //let metadata = {}
      let extension = ''
      if (this.layer.type_of_layer == RASTER_TYPE) {
        // metadata = {
        //   type: 'image/tiff',
        // }
        extension = '.tif'
      } else if (this.layer.type_of_layer == VECTOR_TYPE) {
        // metadata = {
        //   type: 'text/csv',
        // }
        extension = '.csv'
      } else {
        console.warn('This CM layer type is not known: ', this.layer)
        this._toasterService.showDangerToaster('This layer type is not known')
        return
      }

      const file = new File([blob], this.layer.name + extension)

      const layerInfo = {
        workspaceName: this.layer.workspaceName,
        layer_type: this.layer.layer_type,
      }

      this._uploadService.add(file, 'false', layerInfo)
    })
  }
}
