import { Injectable } from '@angular/core'
import * as L from 'leaflet'

@Injectable({
  providedIn: 'root',
})
export class GeojsonService {
  constructor() {}

  drawGeoJSONFromFile(geoJson: any, map: L.Map) {
    let layerGroup = new L.FeatureGroup()
    geoJson.features.forEach((feature) => {
      const geom = feature.geometry
      let layer

      if (geom.type == 'LineString' || geom.type == 'Polygon' || geom.type == 'MultiPolygon') {
        layer = L.geoJSON(feature)
        layerGroup.addLayer(layer)
      } else if (geom.type == 'Point') {
        layer = this._constructPointLeaflet(feature)
        layerGroup.addLayer(layer)
      } else {
        console.warn('Type not supported', geom.type)
      }

      // Add properties in popup if not empty
      if (Object.keys(feature.properties).length > 0) {
        layer.bindPopup(this._constructPopupOfProperties(feature))
      }

      layerGroup.addTo(map)
    })

    return layerGroup
  }

  private _constructPointLeaflet(feature) {
    let geojsonMarkerOptionsDefault = {
      radius: 4,
      fillColor: '#ffffff',
      color: '#000',
      weight: 1,
      opacity: 1,
      fillOpacity: 0.8,
    }

    const layer = L.geoJSON(feature, {
      pointToLayer: (feature: any, latlng: L.LatLng) => {
        if (feature.style && feature.style.name) {
          // filter out elements without any style.name

          if (feature.style.name == 'circle') {
            // circle marker
            let circleMaker = new L.CircleMarker(latlng, {
              fillColor: feature.style.fill,
              color: feature.style.stroke,
              fillOpacity: 1,
              weight: 1,
              // https://github.com/Leaflet/Leaflet/issues/2824
              radius: +feature.style.size / 2,
            })

            //@ToDo return company name with the same style as the one precompiled in the geoserver
            // Under a custom solution
            if (feature.style.stroke == '#000000' && feature.properties.companyname) {
              // @ToDo: rename company without g (and in geoserver)
              // to differentiate between emission sites and company name
              circleMaker.bindTooltip(feature.properties.companyname, {
                permanent: true,
                direction: 'top',
              })
            }
            return circleMaker

            //@ToDo return piechart with value
          } else if (feature.style.name == 'chart') {
            let svgHtmlPieChart: string = this._constructPieChartSVG(feature.style)

            const svgIcon = L.divIcon({
              html: svgHtmlPieChart,
              className: 'svg-icon',
              iconSize: [feature.style.size, feature.style.size],
            })
            return L.marker(latlng, {
              icon: svgIcon,
            })
          } else {
            let svgIcon: L.DivIcon = L.divIcon({
              // html: svgHtmlType,
              className: 'svg-icon',
            })

            switch (feature.style.name) {
              case 'triangle':
                let svgHtmlTriangle: string = this._constructTriangleSVG(feature.style)
                svgIcon = L.divIcon({
                  html: svgHtmlTriangle,
                  className: 'svg-icon',
                  iconSize: [feature.style.size, feature.style.size],
                })
                break
              case 'square':
                let svgHtmlSquare: string = this._constructSquareSVG(feature.style)
                svgIcon = L.divIcon({
                  html: svgHtmlSquare,
                  className: 'svg-icon',
                  iconSize: [feature.style.size, feature.style.size],
                })
                break
              case 'pentagon':
                // @ToDo create pentagon svg (take triangle and svgIcon as example)
                break
              case 'hexagon':
                // @ToDo create hexagon svg (take triangle and svgIcon as example)
                break
              case 'octogon':
                // @ToDo create octogon svg (take triangle and svgIcon as example)
                // ToDo: check if octogon or octagon
                break
              case 'custom':
                svgIcon = L.divIcon({
                  html: feature.style.customSVG,
                  className: 'svg-icon',
                  iconSize: [feature.style.size, feature.style.size],
                })
                break
            }

            return L.marker(latlng, {
              icon: svgIcon,
            })
          }
        } else {
          return L.circleMarker(latlng, geojsonMarkerOptionsDefault)
        }
      },
    })
    return layer
  }

  private _constructPopupOfProperties(feature) {
    let tableProperties = `<table >
        <tr>
          <th>Key</th>
          <th>Value</th>
        </tr>`
    Object.keys(feature.properties).forEach((property) => {
      if (feature.properties[property] != null) {
        tableProperties =
          tableProperties +
          `<tr>
            <td>${property}</td>
            <td>${feature.properties[property]}</td>
           </tr>`
      }
    })
    tableProperties = tableProperties + `</table>`
    return tableProperties
  }

  private _constructTriangleSVG(style: any): string {
    const size = style.size
    const fill = style.fill
    const stroke = style.stroke

    const halfWidth = size / 2
    const height = (Math.sqrt(3) / 2) * size

    return `<svg height="${height * 2}" width="${size * 2}" xmlns="http://www.w3.org/2000/svg">
          <polygon points="0,${height} ${size},${height} ${halfWidth},0" style="fill:${fill};stroke:${stroke}" />
        </svg>`
  }

  private _constructSquareSVG(style: any): string {
    const size = style.size
    const fill = style.fill
    const stroke = style.stroke

    return `<svg height="${size}" width="${size}" xmlns="http://www.w3.org/2000/svg">
    <rect width="${size}" height="${size}" x="0" y="0" fill="${fill}" stroke-width="3" stroke="${stroke}" />
    </svg>`
  }

  private _constructPieChartSVG(chart: {
    data: { [key: string]: number }
    size: number
    chartOptions: { [key: string]: { fillColor: string } }
  }): string {
    const data = chart.data
    const chartOptions = chart.chartOptions as {
      [key: string]: { fillColor: string }
    }
    const size = chart.size

    // calculating total sum of all values
    const total = Object.values(data).reduce((sum, value) => sum + value, 0)

    // generating base SVG element
    let svg = `<svg height="${size * 3}" width="${size * 3}" viewBox="0 0 ${size * 2.5} ${
      size * 2.5
    }">`

    // add offset grey circle, simulation of shadow
    svg += `<circle r="${size / 2}" cx="${size * 1.45}" cy="${size * 1.45}" fill="grey"
                  stroke="grey"
                  stroke-width="${size}"
      />`

    // add white background circle, simulation of border
    svg += `<circle r="${size / 2}" cx="${size * 1.25}" cy="${size * 1.25}" fill="white"
                  stroke="white"
                  stroke-width="${size + 8}"
      />`

    let startAngle = 0
    let endAngle = 0

    // constructing pie slices
    Object.keys(data).forEach((value) => {
      // Calculate the end angle of the current slice:
      // - adding the percentage of the current category to the previous end angle
      // - scale it to the range of 0 to 360 degrees
      let percentage = Math.round((data[value] / total) * 100)
      endAngle += (percentage / 100) * 360

      // Constructing a circle for each slice
      // - the *1.25 in cx & cy allows for the pie chart to be centered to its view box
      // each circle is a slice of pie that is add to the previous one
      // recuperates the end angle of the previous slice
      let color = chartOptions[value].fillColor
      svg += `<circle
                  r="${size / 2}"
                  cx="${size * 1.25}"
                  cy="${size * 1.25}"
                  fill="transparent"
                  stroke="${color}"
                  stroke-width="${size}"
                  stroke-dasharray="${(percentage / 100) * Math.PI * size},${Math.PI * size}"
                  stroke-dashoffset="${(-startAngle / 360) * Math.PI * size}"
              />`

      // Update the starting angle for the next slice
      startAngle = endAngle
    })

    // add the end html tag of the svg to close it once it iterated on each slice
    svg += '</svg>'

    return svg
  }
}
